export const FEAT_ID_FILLING_MATERIAL = 14;
export const FEAT_ID_REGION = 30;
export const TYPE_ID_USP = 36;
export const FEAT_ID_HEATER_MATERIAL = 5;
export const FEAT_ID_INGREDIENTS = 95;

export const TYPE_COMPETITORS_ID = 279;

export default {
  FEAT_ID_FILLING_MATERIAL,
  FEAT_ID_REGION,
  TYPE_ID_USP,
  FEAT_ID_HEATER_MATERIAL,
};
