import Router from 'App/router';
import { EventDispatcher } from 'App/utils/EventDispatcher';

export default class InterfaceAPI extends EventDispatcher {
  #totals = {
    products: 0,
    components: 0,
    companies: 0,
    contacts: 0,
    trademarks: 0,
    brands: 0,
    patents: 0,
    files: 0,
    consumable_variations: 0,
    types: 0,
    news: 0,
    events: 0,
    users: 0,
    research: 0,
    orders: 0,
    customers: 0,
    courier_delivery_servises: 0,
    projects: 0,
    agents: 0,
    recommended_products: 0,
  };
  navTo = (path) => {
    path && Router.push(path).catch();
  };
  login = {
    popup: false,
    show: () => (this.login.popup = true),
    hide: () => (this.login.popup = false),
  };
  register = {
    popup: false,
    show: () => (this.register.popup = true),
    hide: () => (this.register.popup = false),
  };
  setTotal = (category, value = 0) => {
    this.#totals[category] = value;
  };
  getTotal = (category, defVal = 0) => {
    const { [category]: total } = this.#totals;
    return total || defVal;
  };
  reloadApp = () => {
    this.dispatchEvent({ type: 'app:reload' });
  };
}
