<!--suppress CssUnusedSymbol -->
<template>
  <div>
    <section class="welcome-blue-top">
      <v-container>
        <header>
          <h1>
            <span>{{ $t('Innovation product monitoring platform') }}</span>
          </h1>
          <h2>{{ $t('Stay updated with latest technologies and products developments') }}</h2>
        </header>
        <div class="d-flex justify-center flex-wrap">
          <div
            v-for="(category, key) in categories"
            :key="key"
            :class="{ 'my-8 mx-4': !smDown, 'my-4': smDown }"
            :style="{ width: smDown ? '50%' : 'min-content' }"
          >
            <v-list-item dense>
              <v-list-item-icon :class="{ 'mt-4 mr-4': !xs, 'mr-1': xs }">
                <icon-marker view="icon" :iconsize="xs ? 'small' : smDown ? '' : 'x-large'" :item="{ category }" />
              </v-list-item-icon>
              <v-list-item-content class="white--text">
                <div
                  style="white-space: nowrap"
                  class="font-weight-bold mb-0"
                  :class="{ xs: 'title', 'display-1': smDown && !xs, 'display-2': !smDown }"
                >
                  <AnimatedNum v-if="counters[category] !== null" :number="counters[category]" :time="800" :fps="60" />
                  <v-progress-circular v-else indeterminate />
                </div>
                <div
                  class="title"
                  v-text="$t(`cat.${category}`)"
                  :style="{ fontSize: xs ? '0.7rem !important' : smDown ? '1rem !important' : '1.25rem !important' }"
                />
              </v-list-item-content>
            </v-list-item>
          </div>
        </div>
        <div class="body-1 text-left px-4 mx-auto" style="max-width: 60rem">
          <h3 class="title pb-2">{{ $t('Managing Information The Smart Way') }}</h3>
          <p>{{ $t('managingInformationParagraph1') }}</p>
          <p>{{ $t('managingInformationParagraph2') }}</p>
        </div>
        <div class="d-flex justify-center pt-4">
          <v-btn large color="primary" class="ma-2" style="width: 8rem" @click="Interface.login.show">
            {{ $t('Login') }}
          </v-btn>
          <v-btn
            large
            color="blue white--text"
            class="ma-2"
            style="width: 8rem"
            @click="Interface.navTo('/prices')"
            v-text="$t('Prices')"
          />
        </div>
      </v-container>
    </section>
    <section class="welcome-white-mid">
      <v-card flat tile>
        <v-container class="py-12">
          <div class="body-1 px-4 mx-auto" style="max-width: 60rem">
            <h2 class="display-2 font-weight-bold text-center pb-4">{{ $t('How Do We Collect Information?') }}</h2>
            <div>
              <p>{{ $t('collectingInformationProccessParagraph1') }}</p>
              <p>{{ $t('collectingInformationProccessParagraph2') }}</p>
            </div>
          </div>
        </v-container>
      </v-card>
    </section>
  </div>
</template>

<script>
import Dataman from 'App/Dataman';
import CountersMixin from 'App/mixins/CountersMixin';

export default {
  name: 'WelcomePage',
  components: {
    IconMarker: () => import('Components/IconMarker'),
    AnimatedNum: () => import('Components/AnimatedNum'),
  },
  mixins: [CountersMixin],
  computed: {
    Interface() {
      return this.$store.state.Interface;
    },
    counters() {
      return this.$store.getters.counters;
    },
  },
  data() {
    return {
      categories: [...Dataman.availableCategoriesWithCounters],
    };
  },
  created() {
    this.updateCounters();
    const { query } = this.$route;
    if (query.login !== undefined) {
      this.Interface.login.show();
    }
  },
};
</script>

<style>
.fullscreen-promo-image {
  --min-height: 100vh;
  min-height: var(--min-height);

  --background-color: #0084c6;
  background-color: var(--background-color);
  --color: var(--brand-color--text);
  color: var(--color);

  background-image: url(../assets/promo-si-cr.webp);
  background-size: contain;
  background-attachment: fixed;
  background-position: center;
}

@media (max-width: 480px) {
  .fullscreen-promo-image {
    background-size: cover !important;
  }
}

.root {
  --logo-color: hsl(213, 85%, 55%);
  --brand-color: hsl(212, 34%, 29%);
  --brand-color--text: hsl(226, 27%, 90%);
  --action-color: hsl(359, 59%, 43%);
  --action-color--text: hsl(226, 27%, 90%);

  --back-color: white;
  --back-color-accent: snow;
  --back-color-descent: whitesmoke;

  --text-color: black;
  --text-color-accent: darkslategray;
  --text-color-descent: lightslategray;

  --link-color: firebrick;
  --link-color-accent: darkred;
  --link-color-descent: lightsteelblue;

  --shadow-color: var(--shadow-color--for-light-base);
  --light-shadow: var(--light-shadow--for-light-base);

  --shadow-color--for-light-base: hsla(0, 0%, 0%, 0.3);
  --light-shadow--for-light-base: hsla(0, 0%, 0%, 0.05);
  --shadow-color--for-dark-base: hsla(0, 0%, 100%, 0.2);
  --light-shadow--for-dark-base: hsla(0, 0%, 100%, 0.05);
}

/* welcome-may */

.wingle-logo-img {
  display: block;
  height: 100%;
}

@media (min-width: 600px) {
  /*.wingle-logo-img {*/
  /*  transform: scale(1.25);*/
  /*  transform-origin: top left;*/
  /*}*/
}

.welcome-blue-top {
  padding: 2rem 0;
}

.welcome-blue-top header {
  text-transform: uppercase;
  text-align: center;
}

.welcome-blue-top header h1 {
  font-weight: bold;
  font-size: 2.5em;
  line-height: 1.25;
  letter-spacing: 2px;
}

.welcome-blue-top header h2 {
  font-weight: bold;
  font-size: 1.5em;
  line-height: 1.25;
  letter-spacing: 1px;
  color: black;
  padding-top: 0.5rem;
}
</style>
