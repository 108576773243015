import { apiGet, apiPost, apiPatch, apiDelete } from '../../Dataman';
import { DEALS_PATHS } from './deals_paths';
import Backend from '@/app/Dataman-Backend';

export const commentsApi = {
  orderComments: {
    getList: (params) => apiGet(DEALS_PATHS.dealComments, { params }),
    update: (id, data) => apiPatch(`${DEALS_PATHS.dealComments}/${id}`, { data: data }),
    create: (data) => apiPost(DEALS_PATHS.dealComments, { data }),
    remove: (id) => apiDelete(`${DEALS_PATHS.dealComments}/${id}`),
  },
};

export const commentsFilesApi = {
  ordersCommentsFiles: {
    create: (data) => apiPost(DEALS_PATHS.dealCommentFiles, { data }),
    remove: (id) => apiDelete(`${DEALS_PATHS.dealCommentFiles}/${id}`),
    download: async (id) => {
      const res = await Backend.loadBlobData({
        url: `${DEALS_PATHS.dealCommentFiles}/${id}/file`,
        method: 'get',
        responseType: 'blob',
        headers: { accept: 'application/octet-stream' },
      });
      return res;
    },
  },
};
