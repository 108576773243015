export const SERVICE_HOST = 'https://crm-api.winglegroup.com';

export const DEALS_PATHS = {
  deals: `${SERVICE_HOST}/api/deals`, // /api/product_orders
  deal_status: `${SERVICE_HOST}/api/deal_statuses`, // /api/order_statuses
  agens: `${SERVICE_HOST}/api/agents`,
  contactTypes: `${SERVICE_HOST}/api/contact_point_types`, // /api/media_resource_types
  customers: `${SERVICE_HOST}/api/customers`,
  dealAgentDocuments: `${SERVICE_HOST}/api/deal_agent_documents`, // /api/product_order_agent_invoices
  dealAgentFiles: `${SERVICE_HOST}/api/deal_agent_document_files`, // /api/product_order_agent_files
  dealComments: `${SERVICE_HOST}/api/deal_comments`, // /api/order_comments
  dealCommentFiles: `${SERVICE_HOST}/api/deal_comment_files`, // /api/order_comment_files
  trackingParcels: `${SERVICE_HOST}/api/tracking_parcels`, // /api/tracking_informations
  trackingParcelsFiles: `${SERVICE_HOST}/api/tracking_parcel_files`, // /api/tracking_information_contents
  projects: `${SERVICE_HOST}/api/projects`,
  deliveryServices: `${SERVICE_HOST}/api/courier_delivery_services`,
  paymentTypes: `${SERVICE_HOST}/api/payment_types`,
  report: `${SERVICE_HOST}/api/deals/report`,
};
