import { FT_SINGLE } from '../FilterTypes';
import { CAT_CONTACTS } from 'App/utils/Collections';

const groups = {
  contacts: {
    name: 'Contacts',
    groupOrderIndex: 0,
  },
  general: {
    name: 'General',
    groupOrderIndex: 1,
  },
  other: {
    name: 'Other',
    groupOrderIndex: 2,
  },
  personal_data: {
    name: 'Personal Data',
    groupOrderIndex: 3,
  },
};

export default {
  category: CAT_CONTACTS,
  filters: [
    {
      id: 'id',
      label: 'ID Number',
      type: FT_SINGLE,
      group: groups.general,
      hint: '',
    },
    {
      id: 'name',
      label: 'Name',
      type: FT_SINGLE,
      group: groups.personal_data,
      hint: '',
    },
    {
      id: 'surname',
      label: 'Surname',
      type: FT_SINGLE,
      group: groups.personal_data,
      hint: '',
    },
    {
      id: 'email',
      label: 'E-Mail',
      type: FT_SINGLE,
      group: groups.contacts,
      hint: '',
    },
    {
      id: 'company.name',
      label: 'Company Name',
      type: FT_SINGLE,
      group: groups.other,
      hint: '',
    },
    {
      id: 'position',
      label: 'Position',
      type: FT_SINGLE,
      group: groups.other,
      hint: '',
    },
  ],
  searchBarProp: 'complex_name',
};
