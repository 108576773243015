import { loadFileGroups } from './file';
import Dataman from '@/app/Dataman';

export function loadEventFileGroups() {
  const exclude = [];
  return loadFileGroups(8).then((list) => list.filter((el) => !exclude.includes(el.id)));
}

export async function loadEventThemes() {
  const reponse = await Dataman.Filter.getEventThemes({ itemsPerPage: 300 });
  return reponse.data;
}

export async function loadEventTypes() {
  const reponse = await Dataman.DB.eventTypes.get({ itemsPerPage: 300 });
  return reponse.data;
}
