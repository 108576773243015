/* eslint-disable no-prototype-builtins */
import { baseName } from 'App/utils/Helpers';
import BaseModel from 'Models/BaseModel';
import {
  CAT_BRAND_STAFF_ITEM_STATISTICS,
  CAT_BRAND_STAFF_STATISTICS,
  CAT_BRANDS,
  CAT_COMPANIES,
  CAT_COMPANY_STAFF_ITEM_STATISTICS,
  CAT_COMPANY_STAFF_STATISTICS,
  CAT_COMPONENTS,
  CAT_COMPONENTS_STAFF_ITEM_STATISTICS,
  CAT_COMPONENTS_STAFF_STATISTICS,
  CAT_CONTACT_STAFF_ITEM_STATISTICS,
  CAT_CONTACT_STAFF_STATISTICS,
  CAT_CONTACTS,
  CAT_PATENT_STAFF_ITEM_STATISTICS,
  CAT_PATENT_STAFF_STATISTICS,
  CAT_PATENTS,
  CAT_PRODUCT_STAFF_ITEM_STATISTICS,
  CAT_PRODUCT_STAFF_STATISTICS,
  CAT_PRODUCTS,
  CAT_EVENT_STAFF_ITEM_STATISTICS,
  CAT_EVENT_STAFF_STATISTICS,
  CAT_EVENTS,
} from 'App/utils/Collections';
import moment from 'moment';

export const VALUE_OLD = 'old';
export const VALUE_NEW = 'new';

export const T_ARRAY = 'array';
export const T_OBJECT = 'object';
export const T_NULL = 'null';
export const T_BOOL = typeof false;
export const T_NUMBER = typeof 0;
export const T_STRING = typeof '';

/**
 * @prop value
 * @prop itemType
 * @prop type
 */
export class StatDataValue extends BaseModel {
  get value() {
    return this.props.value;
  }
}

export class StatDataItem extends BaseModel {
  constructor(props) {
    super(props);
    const [oldVal, newVal] = this.props.data;
    this.type = getType(oldVal || newVal);
    this.data = this._prepareData(this.props.data);
    this.hasDiff = this._checkForDiff(this.oldValue.value, this.newValue.value);
  }

  /**
   *
   * @param data
   * @returns {Map<string, StatDataValue>}
   * @private
   */
  _prepareData(data) {
    const entries = data.map((val, i) => {
      const itemType = i === 0 ? VALUE_OLD : VALUE_NEW;
      const type = getType(val);
      return [
        itemType,
        new StatDataValue({
          value: normalizeValue(val, type),
          itemType,
          type,
        }),
      ];
    });
    return new Map(entries);
  }

  _checkForDiff(oldValue, newValue) {
    const { isFlatList, type } = this;
    if (type === T_STRING) {
      return !compareStrings(oldValue || '', newValue || '');
    }
    if (type === T_OBJECT) {
      return oldValue?.id !== newValue?.id;
    }
    if (type === T_ARRAY) {
      const oldLen = oldValue?.length || 0;
      const newLen = newValue?.length || 0;
      if (oldLen !== newLen) {
        return true;
      }
      if (isFlatList) {
        return (
          [
            ...(oldValue || []).filter((el) => !newValue.some((el2) => el2.name === el.name)),
            ...(oldValue || []).filter((el) => !oldValue.some((el2) => el2.name === el.name)),
          ].length > 0
        );
      } else {
        return (
          [
            ...(oldValue || []).filter((el) => !newValue.some((el2) => el2.value === el.value)),
            ...(oldValue || []).filter((el) => !oldValue.some((el2) => el2.value === el.value)),
          ].length > 0
        );
      }
    }
    return oldValue !== newValue;
  }

  get oldValue() {
    return this._proxy.data.get(VALUE_OLD);
  }

  get newValue() {
    return this._proxy.data.get(VALUE_NEW);
  }

  get isFlatList() {
    if (this.type !== T_ARRAY) {
      return false;
    }
    const val = this.oldValue?.value?.[0] || this.newValue?.value?.[0];
    return !val?.hasOwnProperty('value');
  }

  getValue = (type) => {
    return this._proxy.data.get(type);
  };
}

/**
 * @typedef {Object} RelatedItem
 * @prop {number} id
 * @prop {string} name
 * @prop {string} createdAt
 * @prop {string} updatedAt
 */

/**
 * @class
 * @prop {boolean} isCreated
 * @prop {boolean} isUpdated
 * @prop {RelatedItem} observedItem
 * @prop {string} createdAt
 * @prop {string} user
 * @prop {Array<StatData>} items
 */
export class StaffStatistics extends BaseModel {
  static getDefaults = () => ({
    items: [],
  });

  _item_category = null;
  _category = null;

  constructor(props) {
    super(props);
    this.props.items = this._mergeSameItems().map((el) => new StatDataItem(el));
  }

  _mergeSameItems() {
    const map = new Map();
    for (const item of this._proxy.items) {
      const { field, data, action } = item;
      if (map.has(field)) {
        const exists = map.get(field);
        const idx = action.toLowerCase() === 'deleted' ? 0 : 1;
        exists.data[idx] = data[idx];
      } else {
        map.set(field, item);
      }
    }
    return [...map.values()];
  }

  get id() {
    return baseName(this.props['@id']);
  }

  get status() {
    return this.props.isCreated ? 'created' : 'modified';
  }

  get date() {
    return moment(this.props.createdAt).format('MMM DD, YYYY, hh:mm A');
  }

  get itemName() {
    const { observedItem } = this._proxy;
    return observedItem.name || '(n/a)';
  }

  get toObservedItem() {
    const { observedItem } = this._proxy;
    const active = this._item_category && observedItem.id;
    return {
      active,
      route: active ? `/${this._item_category}/${observedItem.id}` : '',
      title: this.itemName,
      target: '_blank',
    };
  }
}

export class BrandStatistics extends StaffStatistics {
  constructor(props) {
    super(props);
    this._category = CAT_BRAND_STAFF_STATISTICS;
    this._item_category = CAT_BRANDS;
  }
}

export class CompanyStatistics extends StaffStatistics {
  constructor(props) {
    super(props);
    this._category = CAT_COMPANY_STAFF_STATISTICS;
    this._item_category = CAT_COMPANIES;
  }
}

export class ComponentStatistics extends StaffStatistics {
  constructor(props) {
    super(props);
    this._category = CAT_COMPONENTS_STAFF_STATISTICS;
    this._item_category = CAT_COMPONENTS;
  }
}

export class ContactStatistics extends StaffStatistics {
  constructor(props) {
    super(props);
    this._category = CAT_CONTACT_STAFF_STATISTICS;
    this._item_category = CAT_CONTACTS;
  }
}

export class PatentStatistics extends StaffStatistics {
  constructor(props) {
    super(props);
    this._category = CAT_PATENT_STAFF_STATISTICS;
    this._item_category = CAT_PATENTS;
  }
}

export class ProductStatistics extends StaffStatistics {
  constructor(props) {
    super(props);
    this._category = CAT_PRODUCT_STAFF_STATISTICS;
    this._item_category = CAT_PRODUCTS;
  }
}

export class EventStatistics extends StaffStatistics {
  constructor(props) {
    super(props);
    this._category = CAT_EVENT_STAFF_STATISTICS;
    this._item_category = CAT_EVENTS;
  }
}

/**
 * @prop {string} action
 * @prop {number} itemId
 * @prop {string} itemName
 * @prop {string} latestModification
 */
export class StaffStatisticsOverview extends BaseModel {
  get status() {
    return this.props.action;
  }

  get date() {
    return moment(this.props.latestModification).format('MMM DD, YYYY, hh:mm A');
  }

  get isCreated() {
    return this.status === 'created';
  }

  get toObservedItem() {
    const { itemId, itemName } = this._proxy;
    const active = this._item_category && itemId;
    return {
      active,
      route: active ? `/${this._item_category}/${itemId}` : '',
      title: itemName,
      target: '_blank',
    };
  }
}

export class BrandStatisticsOverview extends StaffStatisticsOverview {
  constructor(props) {
    super(props);
    this._category = CAT_BRAND_STAFF_STATISTICS;
    this._item_category = CAT_BRANDS;
  }
}

export class CompanyStatisticsOverview extends StaffStatisticsOverview {
  constructor(props) {
    super(props);
    this._category = CAT_COMPANY_STAFF_STATISTICS;
    this._item_category = CAT_COMPANIES;
  }
}

export class ComponentStatisticsOverview extends StaffStatisticsOverview {
  constructor(props) {
    super(props);
    this._category = CAT_COMPONENTS_STAFF_STATISTICS;
    this._item_category = CAT_COMPONENTS;
  }
}

export class ContactStatisticsOverview extends StaffStatisticsOverview {
  constructor(props) {
    super(props);
    this._category = CAT_CONTACT_STAFF_STATISTICS;
    this._item_category = CAT_CONTACTS;
  }
}

export class PatentStatisticsOverview extends StaffStatisticsOverview {
  constructor(props) {
    super(props);
    this._category = CAT_PATENT_STAFF_STATISTICS;
    this._item_category = CAT_PATENTS;
  }
}

export class ProductStatisticsOverview extends StaffStatisticsOverview {
  constructor(props) {
    super(props);
    this._category = CAT_PRODUCT_STAFF_STATISTICS;
    this._item_category = CAT_PRODUCTS;
  }
}

export class EventStatisticsOverview extends StaffStatisticsOverview {
  constructor(props) {
    super(props);
    this._category = CAT_EVENT_STAFF_STATISTICS;
    this._item_category = CAT_EVENTS;
  }
}

const Overviews = {
  BrandStatisticsOverview,
  CompanyStatisticsOverview,
  ComponentStatisticsOverview,
  ContactStatisticsOverview,
  PatentStatisticsOverview,
  ProductStatisticsOverview,
  EventStatisticsOverview,
  StaffStatisticsOverview,
  modelsMap: {
    [CAT_BRAND_STAFF_STATISTICS]: BrandStatisticsOverview,
    [CAT_COMPANY_STAFF_STATISTICS]: CompanyStatisticsOverview,
    [CAT_COMPONENTS_STAFF_STATISTICS]: ComponentStatisticsOverview,
    [CAT_CONTACT_STAFF_STATISTICS]: ContactStatisticsOverview,
    [CAT_PATENT_STAFF_STATISTICS]: PatentStatisticsOverview,
    [CAT_PRODUCT_STAFF_STATISTICS]: ProductStatisticsOverview,
    [CAT_EVENT_STAFF_STATISTICS]: EventStatisticsOverview,
  },
};

const modelsMap = {
  [CAT_BRAND_STAFF_ITEM_STATISTICS]: BrandStatistics,
  [CAT_COMPANY_STAFF_ITEM_STATISTICS]: CompanyStatistics,
  [CAT_COMPONENTS_STAFF_ITEM_STATISTICS]: ComponentStatistics,
  [CAT_CONTACT_STAFF_ITEM_STATISTICS]: ContactStatistics,
  [CAT_PATENT_STAFF_ITEM_STATISTICS]: PatentStatistics,
  [CAT_PRODUCT_STAFF_ITEM_STATISTICS]: ProductStatistics,
  [CAT_EVENT_STAFF_ITEM_STATISTICS]: EventStatistics,
};

export default {
  BrandStatistics,
  CompanyStatistics,
  ComponentStatistics,
  ContactStatistics,
  PatentStatistics,
  ProductStatistics,
  EventStatistics,
  StaffStatistics,
  modelsMap,
  Overviews,
};

function getType(value) {
  if (Array.isArray(value)) {
    return 'array';
  }
  if (typeof value === 'object') {
    if (value === null) {
      return 'null';
    }
    if (value.hasOwnProperty('id')) {
      return 'object';
    } else {
      return 'array';
    }
  }
  return typeof value;
}

function normalizeValue(value, type) {
  switch (type) {
    case 'array':
      return Array.isArray(value) ? value : Object.values(value);
    default:
      return value;
  }
}

function compareStrings(a, b) {
  return a.replace(/\r\n/gm, '\n').trim() === b.replace(/\r\n/gm, '\n').trim();
}
