import { time } from 'App/utils/Helpers';
import {
  CACHED_DATA_CATEGORIES,
  CACHED_DATA_MEDIA_RESOURCE_TYPES,
  CACHED_DATA_MODULES,
  CACHED_DATA_PATENTS_AMOUNT,
  CACHED_DATA_STAFF,
  CACHED_DATA_STAFF_TRACKED,
} from 'App/utils/Collections';

const CACHE_KEYS = [
  CACHED_DATA_MEDIA_RESOURCE_TYPES,
  CACHED_DATA_MODULES,
  CACHED_DATA_STAFF,
  CACHED_DATA_STAFF_TRACKED,
  CACHED_DATA_PATENTS_AMOUNT,
  CACHED_DATA_CATEGORIES,
];

export default {
  methods: {
    getCachedData(key) {
      return {
        data: null,
        lifetime: null,
        expireAt: null,
        ...(this.$store.state.cachedData[key] || {}),
      };
    },
    cacheData(key, data, lifetime = 3600) {
      return this.$store.commit('setCachedData', { key, data, lifetime });
    },
    isCachedDataExpired(key) {
      const { expireAt } = this.getCachedData(key);
      return expireAt <= time();
    },
    isCachedDataEmpty(key) {
      return !this.$store.state.cachedData[key]?.data;
    },
    needCachedDataUpdate(key) {
      return this.isCachedDataEmpty(key) || this.isCachedDataExpired(key);
    },
    invalidateCachedData() {
      console.log('Invalidating cached data');
      this.$store.commit('clearCachedData', CACHE_KEYS);
      for (const key of Object.keys(window.localStorage)) {
        if (/^wims-resources::|columnsFilter/.test(key)) {
          localStorage.removeItem(key);
          console.log(`Removed: ${key}`);
        }
      }
    },
  },
};
