<template>
  <v-card class="demo-user-notification">
    🚀
    <span>
      {{
        $t(
          'DEMO User: Please be aware, your access is restricted to a set number of items in each module.Certain filters may also have limitations.'
        )
      }}
    </span>
  </v-card>
</template>

<script>
export default {
  name: 'DemoUserRoleNotification',
};
</script>

<style lang="scss">
.demo-user-notification {
  position: fixed;
  top: 15px;
  left: 50%;
  transform: translate(-50%);
  z-index: 1000;
  padding: 9px 12px;
  color: orange !important;
  font-weight: 500;
  font-size: 14px;
}
</style>
