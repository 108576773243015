import FilterItem from '../FilterItem';

class FilterSuggestion extends FilterItem {
  valuesStorage = null;

  constructor(conf, subcategory) {
    super(conf, subcategory);
  }

  async suggest(term) {
    let name = term || '';
    return this.#getSuggestions(name);
  }

  async apply(values) {
    this.value = {
      text: values[0],
    };
  }

  resetValue = () => {
    this.value = null;
  };

  collectValues() {
    if (this.isNA) {
      return this.naFilter;
    }
    return {
      [this.property]: this.value?.text || null,
    };
  }

  valuesListNames() {
    return [...this.valuesList].map((v) => v.name);
  }

  #getSuggestions = async (...args) => {
    const { getSuggestions } = this.options;
    if (typeof getSuggestions === 'function') {
      return getSuggestions(...args);
    }
    return [];
  };
}

export default FilterSuggestion;
