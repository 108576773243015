import { FT_LIST, FT_SINGLE } from '../FilterTypes';
import { CAT_FEATURES } from 'App/utils/Collections';
import { loadProductTypes, loadCategories } from './loaders';

/**
 * @param {object} conf
 * @param {string} [conf.id=categories]
 * @param {string} [conf.label=Categories]
 * @param {string} [conf.group=General]
 */
const getCategoryResource = (conf = {}) => {
  return {
    name: 'commonCategories',
    loader: async () => {
      const categories = await loadCategories();
      return [
        {
          id: 'categories',
          label: 'Categories',
          group: groups.general,
          ...(conf || {}),
          type: FT_LIST,
          valuesList: [...categories],
          hide: categories.length < 2,
        },
      ];
    },
  };
};

const SPLITTABLE_HINT = 'You can type multiple values separated by comma, semicolon, or space.';

const groups = {
  general: {
    name: 'General',
    groupOrderIndex: 0,
  },
};

export default {
  category: CAT_FEATURES,
  resources: [getCategoryResource({ id: 'categories.id' })],
  filters: [
    {
      id: 'id',
      label: 'ID Number',
      type: FT_SINGLE,
      group: groups.general,
      splittable: true,
      hint: SPLITTABLE_HINT,
    },
    {
      id: 'translations.name',
      label: 'Name',
      type: FT_SINGLE,
      group: groups.general,
      hint: '',
    },
    {
      id: 'types.id',
      label: 'Types',
      group: groups.general,
      type: FT_LIST,
      getListItems: loadProductTypes,
    },
  ],
  searchBarProp: 'translations.name',
};
