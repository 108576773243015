import Dataman from 'App/Dataman';

export function NewsDomainsLoader() {
  return Dataman.DB.newsDomains.getList({ 'order[name]': 'asc' }).then((res) => {
    return res.data['hydra:member'].map((el) => ({ text: el.name, value: el.id }));
  });
}

export function searchNewsDomains(term) {
  const filter = { name: term, 'order[name]': 'asc' };
  return Dataman.DB.newsDomains.getList(filter).then((res) => {
    return res.data['hydra:member'].map((el) => ({ text: el.name, value: el.id }));
  });
}

export function tagsLoader() {
  return Dataman.DB.newsTags.getList({ 'order[name]': 'asc' }).then((res) => {
    return res.data['hydra:member'].map((el) => ({ text: el.name, value: el.id }));
  });
}

export function searchNewsTags(term) {
  const filter = { name: term, 'order[name]': 'asc' };
  return Dataman.DB.newsTags.getList(filter).then((res) => {
    return res.data['hydra:member'].map((el) => ({ text: el.name, value: el.id }));
  });
}
