import { FT_AUTOCOMPLETE } from '@/components/filters/FilterTypes';

export const dynamicFiltersMap = {
  and_group_or_types: (context) => {
    return {
      categories: context.subCat,
    };
  },
  'and_group_or_products.types': (context) => {
    return {
      categories: context.subCat,
    };
  },
  'and_group_or_manufacturedProducts.types': (context) => {
    return {
      categories: context.subCat,
    };
  },
};

export const commonDynamicFiltersMap = {
  [FT_AUTOCOMPLETE]: (context) => ({
    [context.itemsKey]: context.itemsToLoad,
  }),
};

export function getLoaderDynamicFilter(id, context, type) {
  const filterProvider = getFilterProvider(dynamicFiltersMap[id]);
  const commonFilterProvider = getFilterProvider(commonDynamicFiltersMap[type]);

  const filter = { ...filterProvider(context), ...commonFilterProvider(context) };

  return filter;
}

function getFilterProvider(candidate) {
  if (typeof candidate == 'function') {
    return candidate;
  }
  return () => ({});
}
