import Dataman from 'App/Dataman';
import { loadFileGroups } from './file';
import { FILE_GROUP_MODULE_COMPANY, CAT_TYPES, CAT_PRODUCTS } from 'App/utils/Collections';
import { sortAsc } from 'App/utils/Helpers';
import modules from 'App/utils/Modules';
import ItemMaster from 'App/ItemMaster';

export function suggestCompany(name) {
  return Dataman.DB.getCompanies({ name, properties: ['id', 'name'] }).then((res) => res.data['hydra:member']);
}

export function loadCompanyFileGroups() {
  const exclude = [8];
  return loadFileGroups(FILE_GROUP_MODULE_COMPANY).then((list) => list.filter((el) => !exclude.includes(el.id)));
}

export function companyTypesLoader(parentID = null) {
  const params = {
    properties: ['id', 'name', 'description'],
    itemsPerPage: 400,
    modules: modules[CAT_PRODUCTS],
    'order[translations.name]': 'asc',
  };
  if (parentID) {
    params['parent.id'] = parentID;
  } else {
    params['exists[parent]'] = false;
  }
  return Dataman.DB.types.getList(params).then((res) => {
    return res.data['hydra:member']
      .sort(() => sortAsc('name'))
      .map((el) => ({ text: el.name, value: el.id, description: el.description }));
  });
}

export async function searchCompanyTypes(term, parentID = null) {
  const filter = {
    'translations.name': term,
    relevantOrder: 'name',
    modules: modules[CAT_PRODUCTS],
  };
  if (parentID) {
    filter['parent.id'] = parentID;
  } else {
    filter['exists[parent]'] = false;
  }
  return ItemMaster.getList({
    category: CAT_TYPES,
    filter,
  }).then((data) => {
    return data.map((el) => ({ text: el.name, value: el.id }));
  });
}

export default {
  suggestCompany,
  loadCompanyFileGroups,
  searchCompanyTypes,
};
