import { FT_LIST, FT_SINGLE, FT_DATE, FT_CHECKBOX } from '../FilterTypes';
import { CAT_COMPONENTS } from 'App/utils/Collections';
import { loadProductFileGroups } from './loaders';

const groups = {
  date: {
    name: 'Date',
    groupOrderIndex: 0,
  },
  files: {
    name: 'Files',
    groupOrderIndex: 1,
  },
  general: {
    name: 'General',
    groupOrderIndex: 2,
  },
};

export default {
  category: CAT_COMPONENTS,
  filters: [
    {
      id: 'id',
      label: 'ID Number',
      type: FT_SINGLE,
      group: groups.general,
      hint: '',
    },
    {
      id: 'name',
      label: 'Name',
      type: FT_SINGLE,
      group: groups.general,
      hint: '',
    },
    {
      id: 'marking',
      label: 'Marking code/Type',
      type: FT_SINGLE,
      group: groups.general,
      hint: '',
    },
    {
      id: 'createdAt',
      label: 'Created',
      group: groups.date,
      type: FT_DATE,
    },
    {
      id: 'productWithComponents.product.id',
      label: 'Product ID Number (with Components)',
      group: groups.general,
      type: FT_SINGLE,
      hide: true,
      hint: '',
    },
    {
      id: 'exists[files]',
      label: 'Contains Files',
      fieldConf: {
        label: 'Show items with files',
      },
      group: groups.files,
      type: FT_CHECKBOX,
      defaultValue: () => true,
    },
    {
      id: 'files.fileGroup',
      label: 'Groups',
      group: groups.files,
      type: FT_LIST,
      getListItems: loadProductFileGroups,
    },
  ],
  searchBarProp: 'complex_name',
};
