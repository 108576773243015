import { apiGet } from '../../Dataman';
import { DEALS_PATHS } from './deals_paths';
import Backend from 'App/Dataman-Backend';

const { apiUrlSuffix } = Backend.config;

export const statusApiAdapter = {
  deal_statuses: {
    getList: (params) => apiGet(DEALS_PATHS.deal_status + apiUrlSuffix, { params }),
  },
};
