import BaseModel from 'Models/BaseModel';
import { CAT_FEATURE_OPTIONS, CAT_FEATURES } from '@/app/utils/Collections';
import { DataTableHeader, TableHeaderActions, TableHeaderName } from '@/lib/TypeDefs';
import store from '@/app/store/vuex';
import Dataman from '@/app/Dataman';
import { ROLE_STAFF } from '@/app/constants/Roles';
import { Trans } from 'App/plugins/Translation';
import { mdiDelete, mdiSquareEditOutline } from '@mdi/js';

/**
 * @class
 * @property {number} id
 * @property {{ id: number }} feature
 * @property {{ [locale: string]: { value: string, locale: string } }} translations
 */
export default class FeatureOption extends BaseModel {
  static MODULE = CAT_FEATURE_OPTIONS;
  static category = CAT_FEATURE_OPTIONS;
  static getDefaults = () => {
    return {
      category: FeatureOption.category,
      translations: [],
    };
  };
  static getHeaders = (context) => {
    return [
      new DataTableHeader({
        text: BaseModel.tr('ID'),
        value: 'id',
        width: '1%',
        sortable: true,
      }),
      new TableHeaderName({
        text: BaseModel.tr('Name'),
        prop: 'name',
        sortable: true,
      }),
      new TableHeaderActions({
        text: '',
        actions: ['quickEdit', 'remove'],
        width: '1%',
        cellClass: 'no-wrap',
        actionsData: {
          quickEdit: {
            icon: mdiSquareEditOutline,
            color: 'info',
            hint: BaseModel.tr('Quick edit'),
            visible: context && [CAT_FEATURES].includes(context.cat),
            callback: (item) => {
              return store.dispatch('sendAction', {
                action: 'quick-edit',
                data: { category: FeatureOption.MODULE, item },
              });
            },
          },
          remove: {
            icon: mdiDelete,
            color: 'red',
            hint: BaseModel.tr('Delete'),
            restrictions: [ROLE_STAFF],
            prompt: true,
            callback: (item) => {
              return Dataman.DB[CAT_FEATURE_OPTIONS].remove(item.id);
            },
            emit: 'reload',
            messages: {
              promptTitle: BaseModel.tr('Are you sure you want to delete this item?'),
              success: BaseModel.tr('The item has been deleted'),
            },
          },
        },
      }),
    ];
  };

  get name() {
    const { translations } = this.props;
    return translations[Trans.currentLanguage].value;
  }
}
