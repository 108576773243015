import FilterItem from '../FilterItem';

class FilterMultiStrategy extends FilterItem {
  #initialValue = { or: [''], and: [''] };
  constructor(conf, subcategory) {
    super(conf, subcategory);
    this.value = this.#initialValue;
  }

  async apply(_, valuesMap) {
    const value = {};
    for (let key in valuesMap) {
      if (key.includes('and')) {
        value.and = valuesMap[key];
      }
      if (key.includes('or')) {
        value.or = valuesMap[key];
      }
    }
    this.value = value;
    this.dispatchEvent({ type: 'updateValue', value });
  }

  resetValue = () => {
    this.value = {
      or: [''],
      and: [''],
    };
  };

  collectValues() {
    const types = Object.keys(this.value);
    let filter = {};
    for (let t of types) {
      const values = this.value[t].filter(Boolean);
      filter[`${this.property}${`[${t}]`}`] = values;
    }
    return filter;
  }

  getMultiStrategyValues(type) {
    return this.value[type];
  }

  getMultiStrategyValue(type, index) {
    return this.value[type][index];
  }

  addMultiStrategyField(type) {
    this.value[type].push('');
  }
}

export default FilterMultiStrategy;
