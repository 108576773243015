export const DEFAULT_LANGUAGE = 'en';
export const FALLBACK_LANGUAGE = 'en';
export const SUPPORTED_LANGUAGES = ['en', 'zh'];

export const LANGUAGES_LIST = [
  {
    code: 'en',
    title: 'English',
    displayCode: 'en',
    fullCode: 'en_GB',
  },
  {
    code: 'zh',
    title: 'Chinese (中文)',
    displayCode: 'cn',
    fullCode: 'zh_CN',
  },
];
