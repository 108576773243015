import Dataman from 'App/Dataman';
import { sortAsc } from 'App/utils/Helpers';

export async function loadActivitySpheres() {
  const { data } = await Dataman.DB.activitySpheres.getList({
    params: { itemsPerPage: 300 },
  });
  return data.sort(() => sortAsc('name'));
}

export default {
  loadActivitySpheres,
};
