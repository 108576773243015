import { FT_SINGLE, FT_CONDITION_GROUP, FT_AUTOCOMPLETE } from '../FilterTypes';
import { CAT_BRANDS } from 'App/utils/Collections';
import { searchBandTypes, brandTypesLoader, searchCompany } from './loaders';

const groups = {
  general: {
    name: 'General',
    groupOrderIndex: 0,
  },
  products: {
    name: 'Products',
    groupOrderIndex: 1,
  },
  company: {
    name: 'Company',
    groupOrderIndex: 2,
  },
};

export default {
  category: CAT_BRANDS,
  filters: [
    {
      id: 'id',
      label: 'ID Number',
      type: FT_SINGLE,
      group: groups.general,
      hint: '',
    },
    {
      id: 'and_group_or_products.types',
      propertyName: 'and_group_or_products.types',
      label: 'Product Type',
      group: groups.products,
      type: FT_CONDITION_GROUP,
      placeholder: 'Choose type',
      fieldConf: {
        searcher: searchBandTypes,
        items: brandTypesLoader,
      },
    },
    {
      id: 'tradingCompanies.id',
      label: 'Trading company',
      type: FT_AUTOCOMPLETE,
      group: groups.company,
      fieldConf: {
        searcher: searchCompany,
        items: searchCompany,
      },
    },
    {
      id: 'products.manufacturers.id',
      label: 'Manufacturer',
      type: FT_AUTOCOMPLETE,
      group: groups.company,
      fieldConf: {
        searcher: searchCompany,
        items: searchCompany,
      },
    },
  ],
  searchBarProp: 'complex_name',
};
