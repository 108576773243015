import FilterItem from '../FilterItem';
import { STRATEGY_NOT } from 'Components/filters/config';
import { isEmpty } from 'Components/filters/Utils';
import parseRangeRawKey from '@/app/utils/parseRangeRawKey';

class FilterRange extends FilterItem {
  #initialValue = { from: null, to: null };
  constructor(conf, subcategory) {
    super(conf, subcategory);
    this.value = this.#initialValue;
  }
  collectValues() {
    if (this.isFeature) {
      return {
        __feature: this.#collectFeatures(),
      };
    }

    return {
      ...this.#buildCollectedValuesField('after', this.value.from, 'gte'),
      ...this.#buildCollectedValuesField('before', this.value.to, 'lte'),
    };
  }

  async apply(featureValues, keysMap) {
    let value = { from: null, to: null };
    if (/\.{3}/.exec(featureValues?.[0] || '') !== null) {
      const range = featureValues[0].split('...');
      value.from = range[0];
      value.to = range[1];
    } else {
      for (let key in keysMap) {
        value[parseRangeRawKey(key)] = keysMap[key];
      }
    }
    this.value = value;
    this.dispatchEvent({ type: 'updateValue', value });
  }

  resetValue = () => {
    this.value = this.#initialValue;
  };

  #collectFeatures() {
    const { featureID, value, strategy } = this;

    if (this.isNA) {
      return { [featureID]: null, not: true };
    }

    const entry = { [featureID]: value };
    const { from, to } = value;
    entry[featureID] = [from, to].map((el) => (isEmpty(el) ? '' : el)).join('...');

    if (strategy === STRATEGY_NOT) {
      entry.not = true;
    }

    return entry;
  }

  #buildCollectedValuesField(key, value, dateCompareType) {
    if (!value) {
      return {};
    }
    return {
      [`${this.property}[${this.isLteGte ? dateCompareType : key}]`]: value,
    };
  }
}

export default FilterRange;
