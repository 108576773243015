import { CAT_AGENTS } from 'App/utils/Collections';
import { loadLocations } from './loaders';
import { FT_AUTOCOMPLETE, FT_CHECKBOX } from '../FilterTypes';

const groups = {
  general: {
    name: 'General',
    groupOrderIndex: 0,
  },
};

export default {
  category: CAT_AGENTS,
  filters: [
    {
      id: 'country',
      label: 'Country',
      type: FT_AUTOCOMPLETE,
      group: groups.general,
      fieldConf: {
        searcher: loadLocations,
        items: loadLocations,
      },
    },
    {
      id: 'active',
      label: 'Is active',
      type: FT_CHECKBOX,
      hint: '',
      group: groups.general,
      defaultValue: () => true,
    },
  ],
  searchBarProp: 'complex_name',
};
