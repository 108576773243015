import { STRATEGY_AND, STRATEGY_OR } from 'Components/filters/config';
import { FT_DATE, FT_RANGE } from 'Components/filters/FilterTypes';
import { isEmpty } from 'Components/filters/Utils';
import { EventDispatcher } from '@/app/utils/EventDispatcher';
import { uuidv4 } from '@/app/utils/uuid';
import { getLoaderDynamicFilter } from './config/loaders/dynamicFilters/dynamicFIlters';

export default class FilterItem extends EventDispatcher {
  /**
   * @type {string}
   */
  subCat;
  #options;
  #value = null;
  /**
   * @type {boolean}
   */
  #active = false;
  /**
   * @type {boolean}
   */
  #initialized = false;
  /**
   * @type {Array}
   */
  strategy = STRATEGY_OR;
  na = false;
  loadersContext = {};
  tooltipText = null;
  initialLoadersContext = {};
  /**
   * @param {Object} options
   * @param {Filter} filter
   * @param {any} [initialValue=null]
   * @param {string} options.id
   * @param {string} options.type
   * @param {string} options.group
   * @param {string} [options.groupLabel]
   * @param {boolean} [options.useStrategies]
   * @param {boolean} [options.hasNotStrategy]
   * @param {Object} [options.strategyIds]
   * @param {string} [options.strategy]
   * @param {function} [options.getListItems]
   * @param {Object} [options.fieldConf]
   * @param {function} [options.defaultValue]
   * @param {boolean} [options.hide]
   * @param {boolean} [options.isFeature]
   * @param {number} [options.featureID]
   */
  constructor(options, subCat) {
    super();
    this.#options = options;
    this.#value = this.initValue();
    this.subCat = subCat;
    this.tooltipText = this.#options.tooltipText;
    this.uuid = uuidv4();
    this.strategy = this.#options.strategy || STRATEGY_AND;
    this.initialLoadersContext = {
      subCat: this.subCat,
      uuid: this.uuid,
      id: this.id,
    };
    this.resetLoadersContext();
  }
  init = async () => {
    return this.initFilter();
  };

  initLoaders(loaderContext, fieldConf) {
    const loaderHandler = ([key, value]) => {
      if (typeof value == 'function') {
        const loaderWithContext = value.bind(loaderContext);
        return [key, loaderWithContext];
      }
      return [key, value];
    };

    if (fieldConf) {
      const binded = Object.entries(fieldConf).map(loaderHandler);
      return Object.fromEntries(binded);
    }
    return {};
  }

  resetLoadersContext() {
    this.loadersContext = {
      ...this.initialLoadersContext,
    };
  }

  async initFilter() {
    if (this.#initialized) {
      return true;
    }
    this.#initialized = true;
    return this.#initialized;
  }

  async apply(value) {
    this.value = value;
  }

  initValue = () => {
    const defaultValue = this.#options.defaultValue;
    if (defaultValue) {
      if (typeof defaultValue !== 'function') {
        throw new Error(this.#options.id + ': Default value must be a function!');
      }
      return defaultValue();
    }
    return null;
  };
  collectValues() {
    if (this.isNA) {
      return this.naFilter;
    }
    return { [this.property]: this.value };
  }
  get isNA() {
    return this.na && this.useNASearch;
  }
  get naFilter() {
    return { [this.property]: this.inverseNA };
  }
  get initialized() {
    return this.#initialized;
  }
  get id() {
    return this.#options.id;
  }
  get type() {
    return this.#options.type;
  }
  get multiValues() {
    return this.#options.multiValues;
  }
  get group() {
    return this.#options.group;
  }
  get groupLabel() {
    return this.#options.groupLabel || this.group;
  }
  get fieldConf() {
    this.loadersContext.filter = getLoaderDynamicFilter(this.id, this.loadersContext, this.type);
    return this.initLoaders(this.loadersContext, this.#options.fieldConf);
  }
  get label() {
    return this.fieldConf?.label || this.#options.label || this.id;
  }
  get useStrategies() {
    return this.#options.useStrategies || false;
  }
  get hasNotStrategy() {
    return this.#options.hasNotStrategy || false;
  }
  get strategyIds() {
    return this.#options.strategyIds || {};
  }
  get propertyName() {
    return this.#options.propertyName || null;
  }
  get defaultValue() {
    const { defaultValue } = this.#options;
    if (typeof defaultValue === 'function') {
      return defaultValue();
    }
    return defaultValue || null;
  }
  get isHidden() {
    return this.#options.hide || false;
  }
  get isFeature() {
    return this.#options.isFeature || false;
  }
  get featureID() {
    return this.#options.featureID || null;
  }
  get useNASearch() {
    return this.#options.useNASearch || false;
  }
  get inverseNA() {
    return this.#options.inverseNA || false;
  }
  get splittable() {
    return this.#options.splittable || false;
  }
  get splittedValue() {
    const { splittable, value } = this;
    return splittable && typeof value === 'string' ? value.split(/[,;|\s]/gim).filter(Boolean) : [];
  }
  get hint() {
    return this.#options.hint;
  }
  get useMoreButton() {
    return this.#options.useMoreButton || false;
  }
  get role() {
    return this.#options.role || null;
  }
  get isActive() {
    return this.#active;
  }
  get value() {
    return this.#value;
  }
  get options() {
    return this.#options;
  }
  set value(value) {
    if (this.type === FT_DATE || this.type === FT_RANGE) {
      if (value === null) {
        this.#value = { from: null, to: null };
      } else if (Array.isArray(value)) {
        const [from, to] = value;
        this.#value = { ...this.#value, from: from || null, to: to || null };
      } else if ('prop' in value) {
        const { prop, value: _value } = value;
        this.#value = { ...this.#value, [prop]: _value };
      } else if ('to' in value || 'from' in value) {
        this.#value = { ...this.#value, ...value };
      }
    } else {
      this.#value = value;
    }
    super.dispatchEvent({ type: 'updateValue', value });
  }
  resetValue = () => {
    this.#value = null;
  };
  resetNAValue = () => {
    this.na = false;
  };
  activate = () => {
    this.#active = true;
  };
  deactivate = () => {
    this.#active = false;
  };
  get isEmpty() {
    let empty = isEmpty(this.#value);
    if (this.useNASearch) {
      empty = empty && !this.na;
    }
    return empty;
  }
  get isLteGte() {
    return this.#options.isLteGte || false;
  }
  get property() {
    const { id, strategy, strategyIds, useStrategies, propertyName, useNASearch, na } = this;
    let prop = propertyName || id;
    if (useNASearch && na) {
      prop = this.#options.naProp || prop;
    } else if (useStrategies) {
      prop = strategyIds[strategy] || prop;
    }
    return prop;
  }
}
