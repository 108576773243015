import Modules from 'Lib/enums/ApiModules';
import File from 'Models/File';
import FeatureOption from 'Models/FeatureOption';
import Company from '@/models/Company';

class ApiModule {
  #props = {};

  constructor(props) {
    Object.assign(this.#props, props);
  }

  get model() {
    return this.#props.model;
  }
  get formModel() {
    return this.#props.formModel;
  }
}

export const MODULES_MAP = {
  [Modules.MODULE_FILES]: new ApiModule({
    model: File,
    formModel: () => import('Models/form-models/File'),
    api: {
      _base: '/api/files',
      item: '/api/files/{id}',
      briefly: '/api/files/briefly',
      company: '/files/companies/file/{id}',
      components: '/files/components/file/{id}',
      products: '/files/products/file/{id}',
    },
  }),
  [Modules.MODULE_FEATURE_OPTIONS]: new ApiModule({
    model: FeatureOption,
    formModel: () => import('Models/form-models/FeatureOption'),
    api: {
      _base: '/api/feature_options',
      item: '/api/feature_options/{id}',
    },
  }),
  [Modules.MODULE_COMPANIES]: new ApiModule({
    model: Company,
    formModel: () => import('Models/form-models/FeatureOption'),
    api: {
      _base: '/api/companies',
      item: '/api/companies/{id}',
    },
  }),
};
