export const CATEGORY_PLURAL_TO_SINGLE = {
  products: 'product',
  companies: 'company',
  brands: 'brand',
  patents: 'patent',
  trademarks: 'trademark',
  components: 'component',
  contacts: 'contact',
  researches: 'research',
  events: 'event',
  news: 'news',
};
