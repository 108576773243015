import { apiGet, apiPost, apiPatch } from '../../Dataman';
import { DEALS_PATHS } from './deals_paths';
import Backend from 'App/Dataman-Backend';

const { apiUrlSuffix } = Backend.config;

export const projectsApiAdapter = {
  projects: {
    getList: (params) => apiGet(DEALS_PATHS.projects + apiUrlSuffix, { params }),
    getItem: (id, params) => apiGet(`${DEALS_PATHS.projects}/${id}`, { params }),
    update: (id, data) => apiPatch(`${DEALS_PATHS.projects}/${id}`, { data: createProjectBodyAdapter(data) }),
    create: (data) => apiPost(DEALS_PATHS.projects, { data: createProjectBodyAdapter(data) }),
  },
};

function createProjectBodyAdapter(body) {
  return {
    ...body,
    customers: (body.customers || []).map((id) => id.toString()),
  };
}
