export default class FilterListStorage {
  valuesList = [];

  getValueLabel = (id) => {
    const item = (this.valuesList || []).find((el) => {
      const _id = el.value || el.id;
      return id == _id;
    });
    return item ? item.name || item.text : `id:${id}`;
  };

  setValuesList(valuesList) {
    this.valuesList = valuesList;
  }

  addToValuesList(item) {
    this.valuesList.push(item);
  }
}
