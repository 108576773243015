import Dataman from 'App/Dataman';
import { checkExpiration } from 'App/utils/Helpers';

export default {
  computed: {
    cachedCounters() {
      return this.$store.getters || {};
    },
    isCountersExpired() {
      const { lastUpdate } = this.$store.state.countersCache;
      return checkExpiration(lastUpdate);
    },
  },
  methods: {
    async updateCounters() {
      if (!this.isCountersExpired) {
        return this.cachedCounters;
      }
      return Dataman.getAllCounters().then(
        (data) => {
          const counters = Object.fromEntries(data.map((el) => [el.category, el.value]));
          this.$store.dispatch('setCounters', counters);
          return counters;
        },
        (err) => {
          console.error(err);
          return this.cachedCounters;
        }
      );
    },
  },
};
