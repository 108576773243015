import { FT_AUTOCOMPLETE, FT_LIST, FT_SINGLE, FT_DATE } from '../FilterTypes';
import { CAT_TRADEMARKS } from 'App/utils/Collections';
import {
  loadNiceClasses,
  searchOffices,
  loadTrademarks,
  searchTrademarkHolders,
  loadTrademarkHolders,
} from './loaders';
import { ROLE_STAFF } from '@/app/constants/Roles';

const SPLITTABLE_HINT = 'You can type multiple values separated by comma, semicolon, or space.';

const groups = {
  general: {
    name: 'General',
    groupOrderIndex: 0,
  },
  company: {
    name: 'Company',
    groupOrderIndex: 1,
  },
  date: {
    name: 'Date',
    groupOrderIndex: 2,
  },
};

export default {
  category: CAT_TRADEMARKS,
  filters: [
    {
      id: 'companies.id',
      label: 'Company ID',
      type: FT_SINGLE,
      group: groups.company,
      splittable: true,
      hint: SPLITTABLE_HINT,
      role: [ROLE_STAFF],
    },
    {
      id: 'holders.holderName.id',
      label: "Holder's Name",
      type: FT_AUTOCOMPLETE,
      group: groups.company,
      fieldConf: {
        searcher: searchTrademarkHolders,
        items: loadTrademarkHolders,
      },
    },
    {
      id: 'niceClasses.id',
      label: 'Nice Classes',
      type: FT_AUTOCOMPLETE,
      group: groups.general,
      fieldConf: {
        items: loadNiceClasses,
      },
    },
    {
      id: 'name',
      label: 'Name',
      type: FT_SINGLE,
      group: groups.general,
      hint: '',
    },
    {
      id: 'applicationNumber',
      label: 'Application Number',
      type: FT_SINGLE,
      group: groups.general,
      hint: '',
    },
    {
      id: 'applicationDate',
      label: 'Application Date',
      type: FT_DATE,
      group: groups.date,
    },
    {
      id: 'registrationNumber',
      label: 'Registration Number',
      type: FT_SINGLE,
      group: groups.general,
      hint: '',
    },
    {
      id: 'registrationDate',
      label: 'Registration Date',
      type: FT_DATE,
      group: groups.date,
    },
    {
      id: 'status.name',
      label: 'Status',
      type: FT_LIST,
      group: groups.general,
      getListItems: () => [
        {
          id: 'pending',
          name: 'Pending',
        },
        {
          id: 'actual',
          name: 'Actual',
        },
        {
          id: 'failed',
          name: 'Failed',
        },
        {
          id: 'expired',
          name: 'Expired',
        },
      ],
    },
    {
      id: 'registrationOffice.code',
      label: 'Registration Office',
      type: FT_AUTOCOMPLETE,
      group: groups.general,
      fieldConf: {
        searcher: searchOffices,
        items: loadTrademarks,
      },
    },
    {
      id: 'originOffice.code',
      label: 'Origin Office',
      type: FT_AUTOCOMPLETE,
      group: groups.general,
      fieldConf: {
        searcher: searchOffices,
        items: loadTrademarks,
      },
    },
  ],
  searchBarProp: 'complex_name',
};
