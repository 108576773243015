import ItemMaster from 'App/ItemMaster';
import { CAT_TYPES } from '@/app/utils/Collections';

export async function searchProductTypes(term) {
  return ItemMaster.getList({
    category: CAT_TYPES,
    filter: {
      'translations.name': term,
      relevantOrder: 'name',
    },
  });
}
