import store from '@/app/store/vuex.js';
import { FEAT_ID_REGION } from 'Lib/enums/HardcodedIds';

const itemsOnlyForAdmin = {
  [FEAT_ID_REGION]: ['424'],
};

export default function filterItemsByRole(item, id) {
  const isAdmin = store.state.flags.isAdmin;
  const category = itemsOnlyForAdmin[id];

  if (!category || isAdmin) {
    return true;
  }

  return !category.includes(item.id);
}
