const DEAL_TYPE_ID = 1;

export const createRequestBodyAdapter = (requestBody) => {
  return {
    dealItems: (requestBody.dealItems || []).map((dealItem) => {
      const res = {
        name: dealItem.item.name,
        quantity: dealItem.quantity,
      };
      if (dealItem.item.wimsId) {
        res.wimsId = dealItem.item.wimsId;
      }
      return res;
    }),
    comment: requestBody.comment,
    staffComment: requestBody.staffComment,
    urgent: requestBody.urgent,
    customer: requestBody.customer ? '/api/customers/' + requestBody.customer : undefined,
    project: requestBody.project?.toString(),
    status: requestBody.status?.toString(),
    type: DEAL_TYPE_ID.toString(),
  };
};

export const createCustomerBodyAdapter = (requestBody) => {
  return {
    ...requestBody,
    name: requestBody.fullName,
  };
};
