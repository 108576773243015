import { apiGet, apiPost, apiPatch } from '../../Dataman';
import { DEALS_PATHS } from './deals_paths';
import Backend from 'App/Dataman-Backend';

const { apiUrlSuffix } = Backend.config;

export const agentsApiAdapter = {
  agents: {
    getList: (params) => apiGet(DEALS_PATHS.agens + apiUrlSuffix, { params }),
    getItem: (id, params) => apiGet(`${DEALS_PATHS.agens}/${id}`, { params }),
    update: (id, data) => apiPatch(`${DEALS_PATHS.agens}/${id}`, { data: agentsAdapter(data, 'update') }),
    create: (data) => apiPost(DEALS_PATHS.agens, { data: agentsAdapter(data) }),
  },
};
export const paymentTypes = {
  paymentTypes: {
    getList: (params) => apiGet(DEALS_PATHS.paymentTypes + apiUrlSuffix, { params }),
  },
};

function agentsAdapter(requestBody) {
  const contactPoints = (requestBody.mediaResources || []).map((item) => {
    return {
      contactPointType: `${item.mediaResourceType}`,
      value: item.value,
    };
  });

  const payments = (requestBody.payments || []).map((item) => {
    return {
      value: item.value,
      note: item.note,
      primaryPayment: item.isDefault,
      paymentType: `${item.paymentType}`,
    };
  });

  let body = {
    ...requestBody,
    country: `${requestBody.country}`,
    note: requestBody.note,
    contactPoints,
    payments,
    active: true,
  };

  delete body.mediaResources;
  delete body.comment;

  if (!requestBody.country) {
    delete body.country;
  }

  return body;
}
