import { CAT_PRODUCTS } from '@/app/utils/Collections';

export default {
  [CAT_PRODUCTS]: {
    types: {
      value: 'id',
      items: [
        69, 68, 15, 64, 39, 37, 30, 118, 47, 5, 119, 137, 161, 187, 16, 17, 25, 58, 57, 43, 171, 125, 31, 135, 166, 136,
        214, 143, 35, 139, 85, 144, 24, 185, 114, 80, 23, 10, 6, 9, 60, 54, 131, 61, 26, 140, 184,
      ],
    },
  },
};
