/** Userbox API - saved bookmarks, settings e.t.c.
 *  Userbox in state
 *    import Userbox from '@/app/Userbox.js'
 *    Userbox.Connect()
 *    state = { Userbox, ...state }
 *
 *  Userbox in use
 *    const Userbox = function () { return this.$store.state.Userbox }
 *    computed = { Userbox, ...computed }
 *
 */

export default class UserboxAPI {
  bookmarks = [];

  ReMark = (it) => {
    if (this.GetMark(it) === false) {
      this.SetMark(it);
    } else {
      this.RemMark(it);
    }
  };
  SetMark = (it) => {
    if (this.GetMark(it) !== false) {
      return false;
    }
    this.bookmarks.push(it);
    it.bookmarked = true;
  };
  RemMark = (it) => {
    let mark = this.GetMark(it);
    if (!mark || mark.index === false) {
      return false;
    }
    this.bookmarks.splice(mark.index, 1);
    it.bookmarked = false;
  };
  GetMark = (item) => {
    const { id, category } = item;
    const index = this.bookmarks.findIndex((el) => id === el.id && category === el.category);
    if (index < 0) {
      return false;
    }
    return { index, id, category, item: this.bookmarks[index] };
  };
}
