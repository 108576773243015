import { FT_LIST, FT_SINGLE, FT_DATE, FT_MULTI_STRATEGY, FT_CHECKBOX } from '../FilterTypes';
import { CAT_PATENTS } from 'App/utils/Collections';
import { ROLE_STAFF } from 'App/constants/Roles';

const groups = {
  general: {
    name: 'General',
    groupOrderIndex: 0,
  },
};

export default {
  category: CAT_PATENTS,
  filters: [
    {
      id: 'companies.name',
      label: 'Company Name',
      type: FT_SINGLE,
      group: groups.general,
      hint: '',
    },
    {
      id: 'products.name',
      label: 'Product name',
      type: FT_SINGLE,
      group: groups.general,
      hint: '',
    },
    {
      id: 'products.id',
      label: 'Product ID',
      type: FT_SINGLE,
      group: groups.general,
      hint: '',
    },
    {
      id: 'concat_name',
      label: 'Document number',
      type: FT_SINGLE,
      group: groups.general,
      hint: '',
    },
    {
      id: 'companies.id',
      label: 'Company ID Number',
      type: FT_LIST,
      group: groups.general,
      hide: true,
    },
    {
      id: 'classification.cpc[tokenized]',
      label: 'Classification CPC',
      type: FT_MULTI_STRATEGY,
      group: groups.general,
      hint: '',
    },
    {
      id: 'classification.ipc[tokenized]',
      label: 'Classification IPC',
      type: FT_MULTI_STRATEGY,
      group: groups.general,
      hint: '',
    },
    {
      id: 'application.date',
      label: 'Application date',
      type: FT_DATE,
      group: groups.general,
    },
    {
      id: 'publication.date',
      label: 'Publication date',
      type: FT_DATE,
      group: groups.general,
    },
    {
      id: 'relevant',
      label: 'Relevant patents',
      type: FT_CHECKBOX,
      group: groups.general,
      hint: '',
      defaultValue: () => true,
      role: [ROLE_STAFF],
    },
  ],
  searchBarProp: 'complex_name',
};
