/**
 * @property {String} message
 * @property {Number} code
 * @property {Object} response
 */
export class HttpError extends Error {
  constructor(message, code, responseObject) {
    super(message);
    this.code = code;
    this.response = responseObject;
  }
}
