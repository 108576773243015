const defaultFallbackImage = require('Assets/wingle_logo_2.svg');

export default {
  bind(el, binding) {
    try {
      const { value } = binding;
      const img = new Image();
      const original = el.src;
      const errorImage = typeof value === 'string' ? value : defaultFallbackImage;

      img.src = original;
      img.onload = () => {
        el.src = original;
      };
      img.onerror = () => {
        el.src = errorImage;
        el.style['object-fit'] = 'contain';
      };
    } catch (e) {
      console.log(e);
    }
  },
};
