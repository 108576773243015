import { dealsAPiAdapter } from './deals';
import { customersApiAdapter } from './customers';
import { projectsApiAdapter } from './projects';
import { statusApiAdapter } from './statuses';
import { commentsApi, commentsFilesApi } from './dealsComments';
import { trackingParcelsApiAdapter } from './trakingParcels';
import { deliverySerivicesApiAdapter } from './deliveryServices';
import { agentsApiAdapter, paymentTypes } from './agents';
import { agentsPaymentsApiAdapter, agetPaymentsApiFiles } from './agentPayments';

export const dealsAPI = {
  ...statusApiAdapter,
  ...trackingParcelsApiAdapter,
  ...dealsAPiAdapter,
  ...agentsPaymentsApiAdapter,
  ...agetPaymentsApiFiles,
  ...commentsApi,
  ...commentsFilesApi,
  ...customersApiAdapter,
  ...agentsApiAdapter,
  ...paymentTypes,
  ...projectsApiAdapter,
  ...deliverySerivicesApiAdapter,
};
