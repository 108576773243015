import { apiPost, ApiError } from '@/app/Dataman';
import Backend from 'App/Dataman-Backend';
/**
 * @param {{
 *   format: String,
 *   entity: String,
 *   template: Number,
 *   hasLogo: Boolean,
 *   defaultImageType: Number,
 *   items: Array<Number>,
 * }} data
 * @returns {Promise<String>}
 */
export async function getReport(data, lang = 'en') {
  const res = await apiPost(Backend.config.apiUrlReportGenerator, {
    data,
    is_protected: true,
    headers: {
      'accept-language': lang,
    },
  });
  if (res.status !== 200) {
    throw new ApiError('Can not get the report', res);
  }
  return res.data.link;
}
