import Dataman from 'App/Dataman';
import { FILE_GROUP_MODULE_PRODUCTS, CAT_TYPES, CAT_PRODUCTS } from 'App/utils/Collections';
import ItemMaster from 'App/ItemMaster';
import { sortAsc } from 'App/utils/Helpers';
import modules from 'App/utils/Modules';

export function loadFileGroups(modules = FILE_GROUP_MODULE_PRODUCTS) {
  return Dataman.Filter.getFilesGroups({ modules, 'order[name]': 'asc' })
    .then((res) => res.data.sort(() => sortAsc('name')))
    .catch(console.error);
}

export function filesProductTypesLoader() {
  return Dataman.DB.types
    .getList({
      properties: ['id', 'name', 'description'],
      itemsPerPage: 400,
      id: [123, 78],
      'exists[parent]': false,
      modules: modules[CAT_PRODUCTS],
      'order[translations.name]': 'asc',
      ...this.filter,
    })
    .then((res) => {
      return res.data['hydra:member']
        .sort(() => sortAsc('name'))
        .map((el) => ({ text: el.name, value: el.id, description: el.description }));
    });
}

export async function filesSearchTypes(term) {
  return ItemMaster.getList({
    category: CAT_TYPES,
    filter: {
      'translations.name': term,
      id: [123, 78],
      relevantOrder: 'name',
      modules: modules[CAT_PRODUCTS],
      'exists[parent]': false,
      ...this.filter,
    },
  }).then((data) => {
    return data.map((el) => ({ text: el.name, value: el.id }));
  });
}

export default {
  filesProductTypesLoader,
  filesSearchTypes,
  loadFileGroups,
};
