<template>
  <v-main class="fill-height">
    <v-container class="password-reset">
      <v-row class="d-flex justify-center">
        <v-col lg="4" md="6" sm="12">
          <v-card v-if="token">
            <v-card-title> Password Reset </v-card-title>
            <v-card-subtitle>
              Your password must be at least {{ MIN_PASS_LENGTH }} characters including lowercase and uppercase letters,
              and digits
            </v-card-subtitle>
            <v-card-text>
              <v-form ref="form" v-model="valid">
                <v-text-field v-model="password" required :rules="passRules" type="password" :label="$t('Password')" />
                <v-text-field
                  v-model="repeat"
                  required
                  :rules="repeatRules"
                  type="password"
                  :label="$t('Repeat Password')"
                />
              </v-form>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn text color="primary" :loading="loading" @click="submit"> {{ $t('Set New Password') }} </v-btn>
            </v-card-actions>
            <v-card-text v-if="reaction.message" class="body-1 error white--text pt-4">
              {{ reaction.message }}
            </v-card-text>
          </v-card>
          <v-card v-else>
            <v-card-title> {{ $t('Invalid token') }} </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import Dataman, { ApiError } from 'App/Dataman';

const MIN_PASS_LENGTH = 6;

export default {
  name: 'PasswordReset',
  data() {
    return {
      valid: false,
      loading: false,
      password: '',
      repeat: '',
      reaction: {
        message: '',
      },
    };
  },
  computed: {
    token() {
      return this.$route.params.token;
    },
    passRules() {
      return [
        (v) => !!v || 'Please enter password',
        (v) => /[A-Z]/.test(v) || 'Password must contain at least one uppercase letter',
        (v) => /[a-z]/.test(v) || 'Password must contain at least one lowercase letter',
        (v) => /[0-9]/.test(v) || 'Password must contain at least one digit',
        (v) => (v || '')?.length >= MIN_PASS_LENGTH || `Password must have at least ${MIN_PASS_LENGTH} symbols`,
      ];
    },
    repeatRules() {
      return [(v) => !!v || 'Please repeat your password', (v) => v === this.password || 'Passwords are not identical'];
    },
    MIN_PASS_LENGTH() {
      return MIN_PASS_LENGTH;
    },
  },
  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }
      const { token, password } = this;
      this.loading = true;
      Dataman.passwordReset
        .reset({ token, new_password: password })
        .then((res) => {
          this.loading = false;
          if (res.status === 200) {
            this.$router.push('/?login');
          } else {
            throw new ApiError('Invalid data', res);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.reaction.message = err.message;
        });
    },
  },
};
</script>
