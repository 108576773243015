import { CAT_PRODUCT_ORDERS } from 'App/utils/Collections';
import { FT_DATE, FT_CHECKBOX, FT_CONDITION_GROUP, FT_AUTOCOMPLETE, FT_SINGLE } from '../FilterTypes';

import {
  statusItemsLoader,
  searchStatusItems,
  loadLocations,
  loadCourierDeliveryServices,
  searchCourierDeliveryServices,
  searchCustomers,
  loadCustomers,
  searchProjects,
  loadProjects,
} from './loaders';

const groups = {
  general: {
    name: 'General',
    groupOrderIndex: 0,
  },
  tracking: {
    name: 'Tracking',
    groupOrderIndex: 1,
  },
  date: {
    name: 'Date',
    groupOrderIndex: 2,
  },
};

export default {
  category: CAT_PRODUCT_ORDERS,
  filters: [
    {
      id: 'dealItems.item.wimsId',
      label: 'Product ID',
      type: FT_SINGLE,
      group: groups.general,
    },
    {
      id: 'dealItems.item.name',
      label: 'Product name',
      type: FT_SINGLE,
      group: groups.general,
    },
    {
      id: 'project.id',
      label: 'Project',
      type: FT_AUTOCOMPLETE,
      group: groups.general,
      fieldConf: {
        searcher: searchProjects,
        items: loadProjects,
      },
    },
    {
      id: 'and_group_or_status',
      propertyName: 'and_group_or_status',
      label: 'Status',
      group: groups.general,
      type: FT_CONDITION_GROUP,
      placeholder: 'Choose status',
      fieldConf: {
        items: statusItemsLoader,
        searcher: searchStatusItems,
      },
    },
    {
      id: 'customer.id',
      label: 'Customer',
      type: FT_AUTOCOMPLETE,
      group: groups.general,
      fieldConf: {
        searcher: searchCustomers,
        items: loadCustomers,
      },
    },
    {
      id: 'urgent',
      label: 'Urgent',
      fieldConf: {
        label: 'Urgent delivery',
      },
      group: groups.tracking,
      type: FT_CHECKBOX,
      defaultValue: () => false,
    },
    {
      id: 'createdAt',
      label: 'Created at',
      group: groups.date,
      type: FT_DATE,
    },
    {
      id: 'updatedAt',
      label: 'Updated at',
      group: groups.date,
      type: FT_DATE,
    },
    {
      id: 'trackingParcels.countryOfDeparture.id',
      label: 'Departure country',
      type: FT_AUTOCOMPLETE,
      group: groups.tracking,
      fieldConf: {
        searcher: loadLocations,
        items: loadLocations,
      },
    },
    {
      id: 'trackingParcels.countryOfDestination.id',
      label: 'Destination country',
      type: FT_AUTOCOMPLETE,
      group: groups.tracking,
      fieldConf: {
        searcher: loadLocations,
        items: loadLocations,
      },
    },
    {
      id: 'trackingParcels.courierDeliveryService.id',
      label: 'Delivery services',
      type: FT_AUTOCOMPLETE,
      group: groups.tracking,
      fieldConf: {
        searcher: searchCourierDeliveryServices,
        items: loadCourierDeliveryServices,
      },
    },
  ],
  searchBarProp: 'complex_name',
};
