import Dataman from 'App/Dataman';
import { sortAsc } from 'App/utils/Helpers';
import { FILE_GROUP_MODULE_PRODUCTS, USP_TYPE_ID, CAT_TYPES, CAT_PRODUCTS } from 'App/utils/Collections';
import { loadFileGroups } from './file';
import ItemMaster from 'App/ItemMaster';

export function loadConsumableVariations() {
  return Dataman.DB.productConsumableVariations
    .getList({
      properties: ['id', 'name', 'description'],
      itemsPerPage: 400,
      'order[name]': 'asc',
      ...this.filter,
    })
    .then((res) => {
      return res.data['hydra:member']
        .sort(() => sortAsc('name'))
        .map((el) => ({ text: el.name, value: el.id, description: el.description }));
    });
}

export function searchConsumableVariations(term) {
  return Dataman.DB.productConsumableVariations
    .getList({
      name: term,
      properties: ['id', 'name', 'description'],
      itemsPerPage: 400,
      'order[name]': 'asc',
      ...this.filter,
    })
    .then((res) => {
      return res.data['hydra:member']
        .sort(() => sortAsc('name'))
        .map((el) => ({ text: el.name, value: el.id, description: el.description }));
    });
}

export function loadProductTypes() {
  return Dataman.Filter.getProductTypes({ itemsPerPage: 300 })
    .then((res) => res.data.sort(() => sortAsc('name')))
    .catch(console.error);
}

export function loadProductStatuses() {
  let ordering = (a, b) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    } else {
      return 0;
    }
  };
  let iPromise = (resolve, reject) => {
    let iResolvePromise = (r) => {
      resolve(r.data.sort(ordering));
    };
    Dataman.Filter.getProductStatuses().then(iResolvePromise).catch(reject);
  };
  return new Promise(iPromise);
}

export function loadProductFileGroups() {
  const exclude = [6];
  return loadFileGroups(FILE_GROUP_MODULE_PRODUCTS).then((list) => list.filter((el) => !exclude.includes(el.id)));
}

export function loadUSPTypes() {
  return Dataman.DB.types
    .getList({
      properties: ['id', 'name', 'description'],
      itemsPerPage: 400,
      'parent.id': USP_TYPE_ID,
      'order[translations.name]': 'asc',
    })
    .then((res) => {
      return res.data['hydra:member']
        .sort(() => sortAsc('name'))
        .map((el) => ({ text: el.name, value: el.id, description: el.description }));
    });
}

export async function searchUSPTypes(term) {
  return ItemMaster.getList({
    category: CAT_TYPES,
    filter: { 'translations.name': term, relevantOrder: 'name', 'parent.id': USP_TYPE_ID },
  }).then((data) => {
    return data.map((el) => ({ text: el.name, value: el.id }));
  });
}

export async function loadCompetitors() {
  return Dataman.DB.types
    .getList({
      properties: ['id', 'name', 'description'],
      itemsPerPage: 400,
      'parent.id': 279,
      'order[translations.name]': 'asc',
    })
    .then((res) => {
      return res.data['hydra:member'].map((el) => ({ text: el.name, value: el.id, description: el.name }));
    });
}

export async function searchCompetitorsTypes(term) {
  return ItemMaster.getList({
    category: CAT_TYPES,
    filter: { 'translations.name': term, relevantOrder: 'name', 'parent.id': 279 },
  }).then((data) => {
    return data.map((el) => ({ text: el.name, value: el.id }));
  });
}

export function productTypesLoader() {
  return Dataman.DB.types
    .getList({
      properties: ['id', 'name', 'description'],
      itemsPerPage: 400,
      'and_group_or_parent[0]': ['not', 36],
      'order[translations.name]': 'asc',
      ...this.filter,
    })
    .then((res) => {
      return res.data['hydra:member']
        .sort(() => sortAsc('name'))
        .map((el) => ({ text: el.name, value: el.id, description: el.description }));
    });
}

export async function searchTypes(term) {
  return ItemMaster.getList({
    category: CAT_TYPES,
    filter: {
      'translations.name': term,
      relevantOrder: 'name',
      'and_group_or_parent[0]': ['not', 36],
      ...this.filter,
    },
  }).then((data) => {
    return data.map((el) => ({ text: el.name, value: el.id }));
  });
}

export async function searchAllTypes(term) {
  return ItemMaster.getList({
    category: CAT_TYPES,
    filter: {
      'translations.name': term,
      relevantOrder: 'name',
      ...this.filter,
    },
  }).then((data) => {
    return data.map((el) => ({ text: el.name, value: el.id }));
  });
}

export async function searchProduct(term) {
  const filter = { name: term, properties: ['id', 'name', 'category'] };
  return ItemMaster.getList({ filter, category: CAT_PRODUCTS }).then((data) => {
    return data.map((el) => ({ text: el.name, value: el.id }));
  });
}

export function loadProducts() {
  const filter = { itemsPerPage: 100, properties: ['id', 'name', 'category'] };
  return Dataman.DB.getProducts(filter).then((res) => {
    return res.data['hydra:member'].sort(() => sortAsc('name')).map((el) => ({ text: el.name, value: el.id }));
  });
}

export default {
  loadConsumableVariations,
  searchConsumableVariations,
  loadProductTypes,
  loadProductStatuses,
  loadProductFileGroups,
  loadUSPTypes,
  searchUSPTypes,
  searchProduct,
  loadProducts,
  productTypesLoader,
  searchTypes,
};
