import { CAT_EVENTS } from '@/app/utils/Collections';
import { FT_LIST, FT_DATE, FT_AUTOCOMPLETE, FT_SINGLE, FT_CHECKBOX } from '../FilterTypes';
import { loadEventFileGroups, loadEventThemes, loadLocations, loadEventTypes } from './loaders';

const groups = {
  general: {
    name: 'General',
    groupOrderIndex: 0,
  },
  date: {
    name: 'Date',
    groupOrderIndex: 1,
  },
};

export default {
  category: CAT_EVENTS,
  filters: [
    {
      id: 'files.fileGroup',
      label: 'Show events with files',
      type: FT_LIST,
      group: groups.general,
      getListItems: loadEventFileGroups,
      defaultValue: () => [18],
    },
    {
      id: 'visitation',
      label: 'Wingle visit',
      type: FT_CHECKBOX,
      hint: '',
      group: groups.general,
      defaultValue: () => true,
    },
    {
      id: 'themes',
      label: 'Themes',
      type: FT_LIST,
      group: groups.general,
      getListItems: loadEventThemes,
    },
    {
      id: 'country',
      label: 'Country',
      type: FT_AUTOCOMPLETE,
      group: groups.general,
      fieldConf: {
        searcher: loadLocations,
        items: loadLocations,
      },
    },
    {
      id: 'city',
      label: 'City',
      type: FT_SINGLE,
      group: groups.general,
    },
    {
      id: 'types',
      label: 'Types',
      type: FT_LIST,
      group: groups.general,
      getListItems: loadEventTypes,
    },
    {
      id: 'startedOn',
      type: FT_DATE,
      label: 'Started on',
      group: groups.date,
    },
    {
      id: 'finishedOn',
      type: FT_DATE,
      label: 'Finished on',
      group: groups.date,
    },
  ],
  searchBarProp: 'complex_name',
  useStrictSearch: false,
};
