import { FT_AUTOCOMPLETE, FT_SINGLE } from '../FilterTypes';
import { CAT_SAMPLES } from 'App/utils/Collections';
import { loadProducts, searchProduct } from './loaders';

const groups = {
  general: {
    name: 'General',
    groupOrderIndex: 0,
  },
};

export default {
  category: CAT_SAMPLES,
  filters: [
    {
      id: 'id',
      label: 'ID Number',
      type: FT_SINGLE,
      group: groups.general,
      hint: '',
    },
    {
      id: 'product.id',
      label: 'Product',
      type: FT_AUTOCOMPLETE,
      group: groups.general,
      fieldConf: {
        searcher: searchProduct,
        items: loadProducts,
      },
    },
    {
      id: 'barcode',
      label: 'Barcode',
      type: FT_SINGLE,
      group: groups.general,
      hint: '',
    },
  ],
  searchBarProp: 'complex_name',
};
