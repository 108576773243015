import {
  FT_AUTOCOMPLETE,
  FT_LIST,
  FT_RANGE,
  FT_SINGLE,
  FT_SUGGESTION,
  FT_DATE,
  FT_CHECKBOX,
  FT_CONDITION_GROUP,
} from '../FilterTypes';
import {
  CAT_PRODUCTS,
  SUB_PRODUCTS_VAPING_DEV_ID,
  SUB_PRODUCTS_TEARDOWN_REPORTS,
  SUB_PRODUCTS_DHW_DEV_ID,
} from 'App/utils/Collections';
import {
  FEAT_ID_FILLING_MATERIAL,
  FEAT_ID_REGION,
  FEAT_ID_HEATER_MATERIAL,
  FEAT_ID_INGREDIENTS,
} from 'Lib/enums/HardcodedIds';
import { ROLE_ADMIN, ROLE_CUSTOMER, ROLE_STAFF } from '@/app/constants/Roles';
import { STRATEGY_OR, STRATEGY_NOT } from './Strategy';
import {
  loadFeatures,
  loadProductStatuses,
  suggestCompany,
  searchBrand,
  loadFeatureListValues,
  loadProductFileGroups,
  searchUSPTypes,
  loadUSPTypes,
  loadLocations,
  productTypesLoader,
  searchTypes,
  loadConsumableVariations,
  searchConsumableVariations,
  searchCompetitorsTypes,
  loadCompetitors,
} from './loaders';

const SPLITTABLE_HINT = 'You can type multiple values separated by comma, semicolon, or space.';

export const groups = {
  business: {
    name: 'Business',
    groupOrderIndex: 0,
  },
  date: {
    name: 'Date',
    groupOrderIndex: 1,
  },
  files: {
    name: 'Files',
    groupOrderIndex: 2,
  },
  general: {
    name: 'General',
    groupOrderIndex: 3,
  },
  innovations: {
    name: 'Innovations',
    groupOrderIndex: 4,
  },
  competitors: {
    name: 'Competitors',
    groupOrderIndex: 5,
  },
  features: {
    name: 'Features',
    groupOrderIndex: 6,
  },
};

export default {
  category: CAT_PRODUCTS,
  resources: [{ name: 'features', loader: async () => await loadFeatures(groups.features) }],
  filters: [
    {
      id: 'id',
      label: 'ID Number',
      type: FT_SINGLE,
      group: groups.general,
      splittable: true,
      hint: SPLITTABLE_HINT,
    },
    {
      id: 'name',
      label: 'Product Name',
      type: FT_SINGLE,
      group: groups.general,
      hint: '',
    },
    {
      id: 'and_group_or_types',
      propertyName: 'and_group_or_types',
      label: 'Product Type',
      group: groups.general,
      type: FT_CONDITION_GROUP,
      placeholder: 'Choose type',
      fieldConf: {
        searcher: searchTypes,
        items: productTypesLoader,
      },
      children: ['and_group_or_types__usp'],
    },
    {
      id: 'productionStatus.id',
      label: 'Production Status',
      group: groups.business,
      type: FT_LIST,
      getListItems: loadProductStatuses,
    },
    {
      id: 'manufacturers.name',
      label: 'Company/Manufacturer',
      group: groups.business,
      type: FT_SUGGESTION,
      getSuggestions: suggestCompany,
      useNASearch: true,
      naProp: 'exists[manufacturers]',
    },
    {
      id: 'brands',
      label: 'Brands',
      type: FT_AUTOCOMPLETE,
      group: groups.business,
      fieldConf: {
        searcher: searchBrand,
        items: searchBrand,
      },
      useNASearch: true,
      naProp: 'exists[brands]',
    },
    {
      id: `feature${FEAT_ID_REGION}`,
      label: 'Launch region',
      type: FT_LIST,
      role: [ROLE_STAFF, ROLE_ADMIN],
      group: groups.business,
      featureID: FEAT_ID_REGION,
      isFeature: true,
      useNASearch: true,
      getListItems: async () => loadFeatureListValues(FEAT_ID_REGION),
    },
    {
      id: 'and_group_or_marketCountries.country',
      label: 'Market Countries',
      group: groups.business,
      type: FT_CONDITION_GROUP,
      placeholder: 'Choose Market Country',
      fieldConf: {
        searcher: loadLocations,
        items: loadLocations,
      },
      useNASearch: true,
      naProp: 'exists[marketCountries]',
    },
    {
      id: `feature${FEAT_ID_FILLING_MATERIAL}`,
      label: 'Filling Material',
      type: FT_LIST,
      group: groups.general,
      featureID: FEAT_ID_FILLING_MATERIAL,
      isFeature: true,
      useStrategies: true,
      strategy: STRATEGY_OR,
      useNASearch: true,
      categories: [SUB_PRODUCTS_VAPING_DEV_ID, SUB_PRODUCTS_TEARDOWN_REPORTS],
      getListItems: async () => loadFeatureListValues(FEAT_ID_FILLING_MATERIAL),
    },
    {
      id: `feature${FEAT_ID_HEATER_MATERIAL}`,
      label: 'Heater Material',
      type: FT_LIST,
      group: groups.general,
      featureID: FEAT_ID_HEATER_MATERIAL,
      isFeature: true,
      useStrategies: true,
      strategy: STRATEGY_OR,
      useNASearch: true,
      hide: true,
      categories: [SUB_PRODUCTS_VAPING_DEV_ID, SUB_PRODUCTS_TEARDOWN_REPORTS],
      getListItems: async () => loadFeatureListValues(FEAT_ID_HEATER_MATERIAL),
    },
    {
      id: `feature${FEAT_ID_INGREDIENTS}`,
      label: 'Ingredients',
      type: FT_LIST,
      group: groups.general,
      featureID: FEAT_ID_INGREDIENTS,
      isFeature: true,
      useStrategies: true,
      strategy: STRATEGY_OR,
      hide: true,
      categories: [SUB_PRODUCTS_VAPING_DEV_ID, SUB_PRODUCTS_TEARDOWN_REPORTS],
      getListItems: async () => loadFeatureListValues(FEAT_ID_INGREDIENTS),
    },
    {
      id: `feature${FEAT_ID_INGREDIENTS}_subcategory`,
      label: 'Ingredients',
      type: FT_LIST,
      group: groups.general,
      featureID: FEAT_ID_INGREDIENTS,
      isFeature: true,
      useStrategies: true,
      strategy: STRATEGY_OR,
      hide: true,
      categories: [SUB_PRODUCTS_VAPING_DEV_ID, SUB_PRODUCTS_TEARDOWN_REPORTS],
      getListItems: async () => loadFeatureListValues(FEAT_ID_INGREDIENTS),
    },
    {
      id: `not_feature${FEAT_ID_FILLING_MATERIAL}`,
      label: 'NOT Filling Material',
      type: FT_LIST,
      group: groups.general,
      featureID: FEAT_ID_FILLING_MATERIAL,
      isFeature: true,
      strategy: STRATEGY_NOT,
      categories: [SUB_PRODUCTS_VAPING_DEV_ID, SUB_PRODUCTS_TEARDOWN_REPORTS],
      getListItems: async () => loadFeatureListValues(FEAT_ID_FILLING_MATERIAL),
    },
    // not dhw
    {
      id: 'variations.variation.id',
      label: 'Consumable Variations',
      group: groups.general,
      type: FT_AUTOCOMPLETE,
      fieldConf: {
        searcher: searchConsumableVariations,
        items: loadConsumableVariations,
      },
      role: [ROLE_STAFF, ROLE_ADMIN],
      useNASearch: true,
      naProp: 'exists[variations]',
      categories: [SUB_PRODUCTS_VAPING_DEV_ID],
    },
    {
      id: 'retailPrice',
      label: 'Retail price',
      type: FT_RANGE,
      group: groups.business,
      isLteGte: true,
    },
    {
      id: 'wholesalePrice',
      label: 'Wholesale Price',
      type: FT_RANGE,
      group: groups.business,
      isLteGte: true,
    },
    {
      id: 'appeared',
      label: 'First Mention',
      group: groups.date,
      type: FT_DATE,
      tooltipText: 'First public mention about the product',
    },
    {
      id: 'createdAt',
      label: 'Created',
      group: groups.date,
      type: FT_DATE,
    },
    {
      id: 'files.createdAt',
      label: 'Files Added',
      group: groups.date,
      type: FT_DATE,
      hide: true,
    },
    {
      id: 'marketCountries.startedOn',
      label: 'Market Launch',
      group: groups.date,
      type: FT_DATE,
      role: [ROLE_STAFF, ROLE_ADMIN],
      categories: [SUB_PRODUCTS_VAPING_DEV_ID, SUB_PRODUCTS_DHW_DEV_ID],
    },
    {
      id: 'exists[files]',
      label: 'Contains Files',
      fieldConf: {
        label: 'Show items with files',
      },
      group: groups.files,
      type: FT_CHECKBOX,
      defaultValue: () => true,
    },
    {
      id: 'files.fileGroup',
      label: 'Groups',
      group: groups.files,
      type: FT_LIST,
      getListItems: loadProductFileGroups,
    },
    {
      id: 'exists[patents]',
      label: 'Patents',
      group: groups.files,
      fieldConf: {
        label: 'Show items with patents',
      },
      role: [ROLE_STAFF, ROLE_ADMIN],
      type: FT_CHECKBOX,
      defaultValue: () => true,
    },
    {
      id: 'company',
      label: 'Company ID Number',
      type: FT_LIST,
      group: groups.general,
      hide: true,
    },
    {
      id: 'manufacturers',
      label: 'Company ID Number (with Brands)',
      type: FT_SINGLE,
      group: groups.general,
      hide: true,
      hint: '',
    },
    {
      id: 'brands.tradingCompanies',
      label: 'Trading Company ID',
      type: FT_SINGLE,
      group: groups.general,
      hide: true,
      hint: '',
    },
    {
      id: 'componentsInProduct.component.id',
      label: 'Component ID Number',
      type: FT_SINGLE,
      group: groups.general,
      hide: true,
      hint: '',
    },
    {
      id: 'patents.id',
      label: 'Patents',
      group: groups.general,
      type: FT_LIST,
      hide: true,
    },
    // not dhw
    {
      id: 'and_group_or_types__usp',
      propertyName: 'and_group_or_types',
      label: 'USP types',
      group: groups.innovations,
      type: FT_CONDITION_GROUP,
      roles: [ROLE_STAFF, ROLE_ADMIN, ROLE_CUSTOMER],
      placeholder: 'Choose USP type',
      fieldConf: {
        searcher: searchUSPTypes,
        items: loadUSPTypes,
      },
      parent: 'and_group_or_types',
      childOptions: {
        childItems: loadUSPTypes,
      },
      categories: [SUB_PRODUCTS_VAPING_DEV_ID],
    },
    // not dhw
    {
      id: 'and_group_or_types__comp',
      propertyName: 'and_group_or_types',
      label: 'Names',
      group: groups.competitors,
      type: FT_CONDITION_GROUP,
      roles: [ROLE_STAFF, ROLE_ADMIN, ROLE_CUSTOMER],
      placeholder: 'Choose type',
      fieldConf: {
        searcher: searchCompetitorsTypes,
        items: loadCompetitors,
      },
      parent: 'and_group_or_types',
      childOptions: {
        childItems: loadCompetitors,
      },
      categories: [SUB_PRODUCTS_VAPING_DEV_ID],
    },
  ],
  searchBarProp: 'complex_name',
};
