import Dataman from 'App/Dataman';
import { sortAsc } from 'App/utils/Helpers';

export function SPDomainsLoader() {
  return Dataman.DB.researchesDomains
    .getList({
      'order[name]': 'asc',
      properties: ['id', 'name'],
      itemsPerPage: 400,
      ...this.filter,
    })
    .then((res) => {
      return res.data['hydra:member'].sort(() => sortAsc('name')).map((el) => ({ text: el.name, value: el.id }));
    });
}

export function searchSPDomains(term) {
  return Dataman.DB.researchesDomains
    .getList({
      'order[name]': 'asc',
      name: term,
      relevantOrder: 'name',
    })
    .then((res) => {
      return res.data['hydra:member'].sort(() => sortAsc('name')).map((el) => ({ text: el.name, value: el.id }));
    });
}

export function SPAuthorsLoader() {
  return Dataman.DB.researchesAuthors
    .getList({
      properties: ['id', 'name'],
      itemsPerPage: 400,
      'order[name]': 'asc',
      ...this.filter,
    })
    .then((res) => {
      return res.data['hydra:member'].sort(() => sortAsc('name')).map((el) => ({ text: el.name, value: el.id }));
    });
}

export function searchSPAuthors(term) {
  return Dataman.DB.researchesAuthors
    .getList({
      'order[name]': 'asc',
      name: term,
      relevantOrder: 'name',
    })
    .then((res) => {
      return res.data['hydra:member'].sort(() => sortAsc('name')).map((el) => ({ text: el.name, value: el.id }));
    });
}

function getCategoryName(name) {
  let nameParts = name.split('/');
  return nameParts[nameParts.length - 1];
}

export function SPCategoriesLoader() {
  return Dataman.DB.researchesCategories
    .getList({
      properties: ['id', 'name'],
      itemsPerPage: 400,
      'order[name]': 'asc',
      ...this.filter,
    })
    .then((res) => {
      return res.data['hydra:member']
        .sort(() => sortAsc('name'))
        .map((el) => ({ text: getCategoryName(el.name), value: el.id }));
    });
}

export function searchSPCategories(term) {
  return Dataman.DB.researchesAuthors
    .getList({
      'order[name]': 'asc',
      name: term,
      relevantOrder: 'name',
    })
    .then((res) => {
      return res.data['hydra:member']
        .sort(() => sortAsc('name'))
        .map((el) => ({ text: getCategoryName(el.name), value: el.id }));
    });
}

export default {
  searchSPDomains,
  SPDomainsLoader,
  searchSPAuthors,
  SPAuthorsLoader,
  searchSPCategories,
  SPCategoriesLoader,
};
