import FilterRange from './FilterRange';
import parseRangeRawKey from '@/app/utils/parseRangeRawKey';

class FilterDate extends FilterRange {
  constructor(conf, subcategory) {
    super(conf, subcategory);
  }

  init() {
    this.value = {
      from: null,
      to: null,
    };
    super.init();
  }

  resetValue = () => {
    this.value = {
      from: null,
      to: null,
    };
  };

  async apply(_, keysMap) {
    this.value = { from: null, to: null };
    for (let key in keysMap) {
      this.value[parseRangeRawKey(key)] = keysMap[key];
    }
  }
}

export default FilterDate;
