import ItemMaster from 'App/ItemMaster';
import { CAT_BRANDS, CAT_TYPES, CAT_PRODUCTS } from 'App/utils/Collections';
import modules from 'App/utils/Modules';
import Dataman from 'App/Dataman';
import { sortAsc } from 'App/utils/Helpers';

export async function searchBrand(term) {
  return ItemMaster.getList({
    category: CAT_BRANDS,
    filter: { name: term, 'order[name]': 'asc', relevantOrder: 'name', ...this.filter },
  }).then((data) => {
    return data.map((el) => ({ text: el.name, value: el.id }));
  });
}

export async function searchCompany(term) {
  return Dataman.DB.getCompanies({
    complex_name: term,
    'order[name]': 'asc',
    ...this.filter,
  }).then((response) => {
    return response.data['hydra:member'].map((el) => ({ text: el.name, value: el.id }));
  });
}

export function brandTypesLoader() {
  return Dataman.DB.types
    .getList({
      properties: ['id', 'name', 'description'],
      itemsPerPage: 400,
      modules: modules[CAT_PRODUCTS],
      'order[translations.name]': 'asc',
      'exists[parent]': false,
      ...this.filter,
    })
    .then((res) => {
      return res.data['hydra:member']
        .sort(() => sortAsc('name'))
        .map((el) => ({ text: el.name, value: el.id, description: el.description }));
    });
}

export async function searchBandTypes(term) {
  return ItemMaster.getList({
    category: CAT_TYPES,
    filter: {
      'translations.name': term,
      relevantOrder: 'name',
      modules: modules[CAT_PRODUCTS],
      'exists[parent]': false,
      ...this.filter,
    },
  }).then((data) => {
    return data.map((el) => ({ text: el.name, value: el.id }));
  });
}

export default {
  searchBrand,
  brandTypesLoader,
  searchBandTypes,
  searchCompany,
};
