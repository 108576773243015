import { CAT_RESEARCHES } from 'App/utils/Collections';
import { FT_AUTOCOMPLETE, FT_CHECKBOX, FT_DATE } from '../FilterTypes';
import {
  searchSPDomains,
  SPDomainsLoader,
  searchSPAuthors,
  SPAuthorsLoader,
  searchSPCategories,
  SPCategoriesLoader,
} from './loaders';
import { ROLE_STAFF } from '@/app/constants/Roles';

const groups = {
  general: {
    name: 'General',
    groupOrderIndex: 0,
  },
  date: {
    name: 'Date',
    groupOrderIndex: 1,
  },
  files: {
    name: 'Files',
    groupOrderIndex: 2,
  },
};

export default {
  category: CAT_RESEARCHES,
  filters: [
    {
      id: 'domain.id',
      label: 'Domain',
      group: groups.general,
      type: FT_AUTOCOMPLETE,
      placeholder: 'Choose domains',
      fieldConf: {
        multiple: true,
        deletebleChips: true,
        chips: true,
        searcher: searchSPDomains,
        items: SPDomainsLoader,
      },
    },
    {
      id: 'authors.id',
      label: 'Authors',
      group: groups.general,
      type: FT_AUTOCOMPLETE,
      placeholder: 'Choose authors',
      fieldConf: {
        multiple: true,
        deletebleChips: true,
        chips: true,
        searcher: searchSPAuthors,
        items: SPAuthorsLoader,
      },
    },
    {
      id: 'categories.id',
      label: 'Categories',
      group: groups.general,
      type: FT_AUTOCOMPLETE,
      placeholder: 'Choose type',
      fieldConf: {
        multiple: true,
        deletebleChips: true,
        chips: true,
        searcher: searchSPCategories,
        items: SPCategoriesLoader,
      },
    },
    {
      id: 'status',
      label: 'Status',
      group: groups.general,
      type: FT_AUTOCOMPLETE,
      placeholder: 'Choose status',
      role: [ROLE_STAFF],
      fieldConf: {
        items: () => [
          {
            text: 'Pending',
            value: 'pending',
          },
          {
            text: 'Approved',
            value: 'approved',
          },
          {
            text: 'Rejected',
            value: 'rejected',
          },
        ],
      },
    },
    {
      id: 'publishedOn',
      label: 'Published On',
      group: groups.date,
      type: FT_DATE,
    },
    {
      id: 'createdAt',
      label: 'Created At',
      group: groups.date,
      type: FT_DATE,
      role: [ROLE_STAFF],
    },
    {
      id: 'updatedAt',
      label: 'Updated At',
      group: groups.date,
      type: FT_DATE,
      role: [ROLE_STAFF],
    },
    {
      id: 'exists[files]',
      label: 'Show Items With Files',
      group: groups.files,
      fieldConf: {
        label: 'Show items with files',
      },
      type: FT_CHECKBOX,
      defaultValue: () => true,
    },
  ],
  searchBarProp: 'complex_name',
};
