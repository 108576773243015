import Dataman, { apiGet } from '@/app/Dataman';
import Backend from 'App/Dataman-Backend';

const { apiUrlProducts, apiUrlSuffix } = Backend.config;

export async function searchProducts(params, cat, headers) {
  const { clearParams, ...rest } = params;
  let imageParams = {};
  if (rest.imageSearch) {
    const formData = new FormData();
    let imageSource = 'file';
    if (typeof rest.imageSearch == 'string') {
      imageSource = 'url';
    }
    formData.append(imageSource, rest.imageSearch);
    formData.append('maxResults', 30);
    const res = await Dataman.DB.searchProductsByImage(formData);
    const ids = res.data?.items?.map((i) => i.id) || [];
    imageParams.valueSequence = ids.join(',');
  }
  let preparedParams = {
    params: { ...rest, ...imageParams },
    clearParams,
    is_protected: true,
  };
  if (headers) {
    preparedParams.headers = headers.headers;
  }
  return apiGet(apiUrlProducts + apiUrlSuffix, preparedParams);
}
