import Dataman from 'App/Dataman';
import { sortAsc } from 'App/utils/Helpers';

export function loadLocations(term) {
  return new Promise((resolve, reject) => {
    Dataman.Filter.getCountries({ params: { itemsPerPage: 300, name: term || '', 'order[translations.name]': 'asc' } })
      .then((res) => {
        return resolve(
          res.data.map(({ id, name }) => ({ id, name, text: name, value: id })).sort(() => sortAsc('name'))
        );
      })
      .catch(reject);
  });
}

export async function loadCountryRegions() {
  const { data } = await Dataman.Filter.getCountryRegions({
    params: { itemsPerPage: 100 },
  });
  return data.sort(() => sortAsc('name'));
}

export default {
  loadLocations,
  loadCountryRegions,
};
