export default class Collection {
  static SCHEME_HYDRA = 'hydra';
  static SCHEME_LIST = 'list';
  #api;
  #apiParams;
  #total = 0;
  #size = 0;
  #items = [];
  #pageItems = [];
  #search = {};
  #view = {};
  #model = null;
  #mapToModel = false;
  #index = new Map();
  #cursor = {
    itemsPerPage: 30,
    page: 1,
  };
  #scheme = Collection.SCHEME_HYDRA;
  #indexData = () => {
    this.#index = new Map();
    for (let i = 0; i < this.#size; i++) {
      this.#index.set(this.#items[i].id, i);
    }
  };
  #setData = (data, offset = null) => {
    let _items;
    if (this.#scheme === Collection.SCHEME_HYDRA) {
      _items = data['hydra:member'];
      this.#total = data['hydra:totalItems'];
      this.#view = data['hydra:view'];
      this.#search = data['hydra:search'];
    } else if (this.#scheme === Collection.SCHEME_LIST) {
      _items = data;
    } else {
      return;
    }
    this.#pageItems = _items;
    if (offset === null) {
      this.#items.push(..._items);
    } else {
      this.#items.splice(offset, _items.length, ..._items);
    }
    this.#size = this.#items.length;
    this.#indexData();
  };
  constructor(data, { model, params, api, scheme = null }) {
    if (data['@type'] === 'hydra:Collection') {
      this.#scheme = Collection.SCHEME_HYDRA;
    } else if (Array.isArray(data)) {
      this.#scheme = Collection.SCHEME_LIST;
    } else {
      this.#scheme = scheme;
    }
    this.#api = api;
    this.#apiParams = params;
    if (this.#apiParams?.options.params !== void 0) {
      ['page', 'itemsPerPage'].forEach((prop) => {
        this.#cursor[prop] = +(this.#apiParams.options.params[prop] || this.#cursor[prop]);
        delete this.#apiParams.options.params[prop];
      });
    }
    this.#setData(data);
    if (model) {
      this.#mapToModel = true;
      this.#model = model;
    }
  }
  *[Symbol.iterator]() {
    for (let i = 0; i < this.#size; i++) {
      const item = this.#items[i];
      yield this.#mapToModel ? new this.#model(item) : item;
    }
  }
  get total() {
    return this.#total;
  }
  get pagesAmount() {
    return Math.ceil(this.#total / this.#cursor.itemsPerPage);
  }
  get items() {
    return [...this];
  }
  get size() {
    return this.#size;
  }
  get currentPage() {
    return this.#cursor.page;
  }
  get pageSize() {
    return +this.#cursor.itemsPerPage;
  }
  get loadedAmount() {
    return this.#items.length;
  }
  #loadPage = async (url) => {
    // eslint-disable-next-line no-unused-vars
    const { params, ...opts } = this.#apiParams.options;
    opts.ld = this.#scheme === Collection.SCHEME_HYDRA;
    const { status, statusText, data } = await this.#api.get(url, opts);
    if (status >= 400) {
      throw new Error(`${status}: ${statusText}`);
    }
    this.#cursor.page = +url.searchParams.get('page');
    this.#setData(data, this.#cursor.itemsPerPage * (this.#cursor.page - 1));
    return this;
  };
  async loadNextPage() {
    let url;
    if (this.#scheme === Collection.SCHEME_HYDRA) {
      if (!this.#view['hydra:next']) {
        return this;
      }
      url = this.#api.buildUrl(this.#view['hydra:next']);
    } else {
      if (!this.#apiParams.endpoint) {
        return this;
      }
      url = this.#api.buildUrl(this.#apiParams.endpoint);
      url.searchParams.set('page', (this.#cursor.page + 1).toString());
      url.searchParams.set('itemsPerPage', this.#cursor.itemsPerPage);
    }
    return this.#loadPage(url);
  }
  async reloadPage(page) {
    const url = this.#api.buildUrl(this.#view['hydra:first']);
    url.searchParams.set('page', page);
    return this.#loadPage(url);
  }
  get pageItems() {
    return this.#pageItems.map((item) => {
      return this.#mapToModel ? new this.#model(item) : item;
    });
  }
  getPageItems(page = null) {
    const { itemsPerPage } = this.#cursor;
    const offset = itemsPerPage * (page - 1);
    return this.#items.slice(offset, offset + itemsPerPage).map((item) => {
      return this.#mapToModel ? new this.#model(item) : item;
    });
  }
  getItems(offset = 0, amount = null) {
    const end = amount === null ? void 0 : offset + amount;
    return this.#items.slice(offset, end).map((item) => {
      return this.#mapToModel ? new this.#model(item) : item;
    });
  }
}
