<template>
  <v-container class="prices" fluid>
    <v-card class="plan-block mb-2 mt-2 pa-3" v-for="(plan, i) in prices" :key="i">
      <div class="plan-content">
        <h3>{{ $t(`prices.${plan}.title`) }}</h3>
        <p>{{ $t(`prices.${plan}.content`) }}</p>
      </div>
      <div class="plan-footer">
        <v-btn
          color="blue white--text"
          class="ma-2"
          style="width: 8rem"
          :disabled="isPriceRequested(i)"
          @click="requestPrice(i)"
          v-text="$t('Get price')"
        />
      </div>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'Prices',
  computed: {
    Interface() {
      return this.$store.state.Interface;
    },
    requests() {
      return this.$store.state.priceRequests;
    },
  },
  methods: {
    isPriceRequested(key) {
      return !!this.requests[key];
    },
    requestPrice(key) {
      if (!this.isPriceRequested(key)) {
        this.$store.dispatch('priceRequest', key);
        this.Interface.register.show();
      }
    },
  },
  data() {
    return {
      prices: ['trial', 'basic', 'pro'],
    };
  },
};
</script>
