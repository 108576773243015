import { FT_LIST, FT_DATE, FT_CONDITION_GROUP } from '../FilterTypes';
import { CAT_FILES } from 'App/utils/Collections';
import {
  loadProductFileGroups,
  loadCompanyFileGroups,
  filesProductTypesLoader,
  filesSearchTypes,
  loadLocations,
} from './loaders';

const groups = {
  general: {
    name: 'General',
    groupOrderIndex: 0,
  },
};

export default {
  category: CAT_FILES,
  filters: [
    {
      id: 'and_group_or_products.types',
      propertyName: 'and_group_or_products.types',
      label: 'Product Type',
      group: groups.general,
      type: FT_CONDITION_GROUP,
      placeholder: 'Choose type',
      fieldConf: {
        searcher: filesSearchTypes,
        items: filesProductTypesLoader,
      },
    },
    {
      id: 'file_group_product',
      propertyName: 'fileGroup',
      label: 'Product File Groups',
      group: groups.general,
      type: FT_LIST,
      getListItems: loadProductFileGroups,
    },
    {
      id: 'file_group_company',
      propertyName: 'fileGroup',
      label: 'Company File Groups',
      group: groups.general,
      type: FT_LIST,
      getListItems: loadCompanyFileGroups,
    },
    {
      id: 'createdAt',
      label: 'Upload Date',
      group: groups.general,
      type: FT_DATE,
    },
    {
      id: 'samples.manufactureDate',
      label: 'Manufacture Date',
      group: groups.general,
      type: FT_DATE,
    },
    {
      id: 'publicationDate',
      label: 'Publication Date',
      group: groups.general,
      type: FT_DATE,
    },
    {
      id: 'associatedCountries.id',
      label: 'Associated Countries',
      group: groups.general,
      type: FT_LIST,
      getListItems: loadLocations,
    },
  ],
};
