import FILTER_CONF_PRODUCTS from './products';
import FILTER_CONF_COMPONENTS from './components';
import FILTER_CONF_COMPANIES from './companies';
import FILTER_CONF_CONTACTS from './contacts';
import FILTER_CONF_BRANDS from './brands';
import FILTER_CONF_PATENT_RESEARCH from './patent-research';
import FILTER_CONF_TRADEMARKS from './trademarks';
import FILTER_CONF_PATENTS from './patents';
import FILTER_CONF_FILES from './files';
import FILTER_CONF_SAMPLES from './samples';
import FILTER_CONF_CONSUMABLE_VARIATIONS from './consumableVariations';
import FILTER_CONF_TYPES from './types';
import FILTER_CONF_SAMPLE_CONDITIONS from './sampleConditions';
import FILTER_CONF_STANDING_REPORTS from './standingReports';
import FILTER_CONF_NEWS from './news';
import FILTER_CONF_COMPANY_NEWS from './companyNews';
import FILTER_CONF_FEATURES from './features';
import FILTER_CONF_EVENTS from './events';
import FILTER_CONF_USERS from './users';
import FILTER_CONF_EVENT_TYPES from './event_types';
import FILTER_CONF_EVENT_THEMES from './event-themes';
import FILTER_CONF_SCHOLARLY_WORKS from './researches';
import FILTER_CONF_PRODUCT_ORDERS from './orders';
import FILTER_CONF_CUSTOMERS from './customers';
import FILTER_CONF_COURIER_DELIVERY_SERVICES from './courierDeliveryServices';
import FILTER_CONF_PROJECTS from './projects';
import FILTER_CONF_AGENTS from './agents';
import FILTER_CONF_RECOMMENDED_PRODUCTS from './recommended-products';

const filterCategories = [
  FILTER_CONF_PRODUCTS,
  FILTER_CONF_COMPONENTS,
  FILTER_CONF_COMPANIES,
  FILTER_CONF_CONTACTS,
  FILTER_CONF_BRANDS,
  FILTER_CONF_PATENT_RESEARCH,
  FILTER_CONF_TRADEMARKS,
  FILTER_CONF_PATENTS,
  FILTER_CONF_FILES,
  FILTER_CONF_SAMPLES,
  FILTER_CONF_CONSUMABLE_VARIATIONS,
  FILTER_CONF_TYPES,
  FILTER_CONF_SAMPLE_CONDITIONS,
  FILTER_CONF_STANDING_REPORTS,
  FILTER_CONF_NEWS,
  FILTER_CONF_COMPANY_NEWS,
  FILTER_CONF_FEATURES,
  FILTER_CONF_EVENTS,
  FILTER_CONF_EVENT_THEMES,
  FILTER_CONF_USERS,
  FILTER_CONF_EVENT_TYPES,
  FILTER_CONF_SCHOLARLY_WORKS,
  FILTER_CONF_PRODUCT_ORDERS,
  FILTER_CONF_CUSTOMERS,
  FILTER_CONF_COURIER_DELIVERY_SERVICES,
  FILTER_CONF_PROJECTS,
  FILTER_CONF_AGENTS,
  FILTER_CONF_RECOMMENDED_PRODUCTS,
];

export const FILTERS_MAP = buildFilterMap(filterCategories);

export * from './Strategy';

export default {
  FILTERS_MAP,
  FILTER_CONF_PRODUCTS,
  FILTER_CONF_COMPONENTS,
  FILTER_CONF_COMPANIES,
  FILTER_CONF_CONTACTS,
  FILTER_CONF_BRANDS,
  FILTER_CONF_PATENT_RESEARCH,
  FILTER_CONF_TRADEMARKS,
  FILTER_CONF_PATENTS,
  FILTER_CONF_FILES,
  FILTER_CONF_SAMPLES,
  FILTER_CONF_CONSUMABLE_VARIATIONS,
  FILTER_CONF_TYPES,
  FILTER_CONF_SAMPLE_CONDITIONS,
  FILTER_CONF_STANDING_REPORTS,
  FILTER_CONF_NEWS,
  FILTER_CONF_COMPANY_NEWS,
  FILTER_CONF_EVENTS,
  FILTER_CONF_USERS,
  FILTER_CONF_SCHOLARLY_WORKS,
  FILTER_CONF_PRODUCT_ORDERS,
  FILTER_CONF_RECOMMENDED_PRODUCTS,
};

function buildFilterMap(filterConfs) {
  const map = {};
  filterConfs.forEach((filterConf) => {
    map[filterConf.category] = filterConf;
  });
  return map;
}
