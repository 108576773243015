import { apiGet, apiPost, apiPatch, apiDelete } from '../../Dataman';
import { DEALS_PATHS } from './deals_paths';
import Backend from '@/app/Dataman-Backend';

const { apiUrlSuffix } = Backend.config;

export const agentsPaymentsApiAdapter = {
  orderAgents: {
    getList: (params) => apiGet(DEALS_PATHS.dealAgentDocuments, { params }),
    update: (id, data) =>
      apiPatch(`${DEALS_PATHS.dealAgentDocuments}/${id}`, { data: createAgentDocumentBodyAdapter(data) }),
    getItem: (id, params) => apiGet(`${DEALS_PATHS.dealAgentDocuments}/${id}`, { params }),
    create: (data) => apiPost(DEALS_PATHS.dealAgentDocuments, { data: createAgentDocumentBodyAdapter(data) }),
    remove: (id) => apiDelete(`${DEALS_PATHS.dealAgentDocuments}/${id}`),
  },
};

export const agetPaymentsApiFiles = {
  orderAgentFiles: {
    getList: (params) => apiGet(DEALS_PATHS.dealAgentFiles + apiUrlSuffix, { params }),
    remove: (id) => apiDelete(`${DEALS_PATHS.dealAgentFiles}/${id}`),
    download: async (id) => {
      const res = await Backend.loadBlobData({
        url: `${DEALS_PATHS.dealAgentFiles}/${id}/file`,
        method: 'get',
        responseType: 'blob',
        headers: { accept: 'application/octet-stream' },
      });
      return res;
    },
    create: (data) => {
      const formData = new FormData();
      formData.append('invoice', data.isInvoice);
      formData.append('file', data.file);
      formData.append('agentDocument', data.agentInvoiceId);
      return Backend.post({
        url: DEALS_PATHS.dealAgentFiles,
        headers: {
          'Content-type': 'multipart/form-data',
        },
        data: formData,
      });
    },
  },
};

function createAgentDocumentBodyAdapter(body) {
  return {
    price: body.price,
    currency: body.currency,
    agent: `${body.agent}`,
    notify: body.notify,
    deals: body.orders.map((order) => `${order}`),
    note: body.note,
    paymentByProjects: body.paymentByProjects.map((payment) => ({
      project: `${payment.project}`,
      price: payment.price,
    })),
  };
}
