import Vue from 'vue';
import { getIterator } from 'App/utils/Helpers';
import { EventDispatcher } from 'App/utils/EventDispatcher';

const next = getIterator();

export class ToastMessage {
  /**
   * @param {Object} props
   * @param {String} props.message
   * @param {number} [props.timeout]
   * @param {string} [props.type]
   */
  constructor(props = {}) {
    this.props = props || {};
    this.id = next();
  }

  get timeout() {
    return Math.max(this.props.timeout || 0, 0);
  }

  get message() {
    return this.props.message;
  }

  get type() {
    return this.props.type;
  }
}

export class Toaster extends EventDispatcher {
  constructor(options = {}) {
    super();
    this.options = { ...options };
    this.queue = [];
  }

  show = (message, params = {}) => {
    const toastMessage = new ToastMessage({ message, ...(params || {}) });
    this.queue.push(toastMessage);
    this.dispatchEvent({ type: 'change', queue: this.queue });
  };

  info = (message, timeout = 3e3, params = {}) => this.show(message, { ...(params || {}), type: 'info', timeout });
  success = (message, timeout = 3e3, params = {}) =>
    this.show(message, { ...(params || {}), type: 'success', timeout });
  warning = (message, timeout = 3e3, params = {}) =>
    this.show(message, { ...(params || {}), type: 'warning', timeout });
  error = (message, timeout = 3e3, params = {}) => this.show(message, { ...(params || {}), type: 'error', timeout });

  remove = (id) => {
    const index = this.queue.findIndex((el) => el.id === id);
    if (index >= 0) {
      this.queue.splice(index, 1);
      this.dispatchEvent({ type: 'change', queue: this.queue });
    }
  };

  getAll = () => {
    return this.queue.splice(0);
  };

  clear = () => {
    const queue = this.queue.splice(0);
    this.dispatchEvent({ type: 'clear', queue: this.queue });
    return queue;
  };
}

export const ToasterSingleton = (function (options = {}) {
  let instance = null;

  function createInstance() {
    instance = new Toaster(options);
    return instance;
  }

  return {
    getInstance() {
      if (instance) {
        return instance;
      }
      return createInstance();
    },
  };
})();

class ToasterPlugin {
  static install(Vue, options = {}) {
    Vue.prototype.$toaster = ToasterSingleton.getInstance(options);
  }
}

Vue.use(ToasterPlugin);

export default new ToasterPlugin();
