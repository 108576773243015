import FilterItem from '../FilterItem';

class FilterCheckbox extends FilterItem {
  constructor(conf, subcategory) {
    super(conf, subcategory);
  }

  async apply(values) {
    this.value = values[0];
  }

  resetValue = () => {
    this.value = false;
  };
}

export default FilterCheckbox;
