import { i18n } from 'App/plugins/i18n';

/**
 * @typedef {object} SimpleRelatedItem
 * @property {number} id
 * @property {string} name
 * @property {string} [createdAt]
 * @property {string} [updatedAt]
 */

export default class BaseModel {
  props = {};
  _raw = {};
  _defs = {};
  #proxy;
  #moduleName;
  static MODULE = null;
  static LD = true;
  static searchConf = null;
  static getHeaders = () => [
    {
      text: 'ID',
      value: 'id',
      width: '1%',
    },
    {
      text: 'Name',
      value: 'name',
    },
  ];
  static getDefaultFilters = () => ({});
  static getDefaultSort = () => ({ by: null, desc: null });
  static getDefaults = () => ({});
  static tr = (key) => i18n.t(key);

  constructor(props = {}) {
    this.setProps(props);
    this._defs = this.constructor.getDefaults();
    this.#proxy = new Proxy(this, {
      get: (target, p) => {
        // if (this.constructor.MODULE == 'companies') {
        //   console.log(props);
        // }
        // console.log(p);
        if (p in target) {
          return target[p];
        }
        // eslint-disable-next-line no-prototype-builtins
        if (target.props.hasOwnProperty(p)) {
          const val = target.props[p];
          return typeof val === 'undefined' ? target._defs[p] : val;
        }
        return target._defs[p];
      },
      set: (target, p, value) => {
        if (p in target.props) {
          target.props[p] = value;
        } else {
          target[p] = value;
        }
        return true;
      },
    });
    this.#moduleName = this.constructor.MODULE;
    return this.#proxy;
  }
  get raw() {
    return this._raw;
  }
  get _proxy() {
    return this.#proxy;
  }
  get moduleName() {
    return this.#moduleName;
  }
  get category() {
    return this.getCategory();
  }
  get title() {
    const { title, name } = this.props;
    return title || name;
  }
}

BaseModel.prototype.setProps = function (props = {}) {
  this._raw = JSON.parse(JSON.stringify(props || {}));
  this.props = { ...props };
};

BaseModel.prototype.getSearchConf = function () {
  return this.constructor.searchConf;
};

BaseModel.prototype.getCategory = function () {
  return this.constructor.category;
};
