import BaseModel from 'Models/BaseModel';
import { normalizeImgUrl } from 'App/utils/Helpers';

/**
 * @property {string} url
 * @property {string} thumbnailUrl
 * @property {boolean} default
 * @property {string} createdAt
 */
export default class Img extends BaseModel {
  constructor(props) {
    super(props);
    this.src = normalizeImgUrl(this.props.url);
    this.thumb = normalizeImgUrl(this.props.thumbnailUrl);
  }

  toString() {
    return this.src;
  }
}
