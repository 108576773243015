export const FT_AUTOCOMPLETE = 'autocomplete';
export const FT_SINGLE = 'single';
export const FT_RANGE = 'range';
export const FT_LIST = 'list';
export const FT_SUGGESTION = 'suggestion';
export const FT_DATE = 'date';
export const FT_CHECKBOX = 'checkbox';
export const FT_SWITCH = 'switch';
export const FT_MULTI_STRATEGY = 'multi-strategy';
export const FT_CONDITION_GROUP = 'condition-group';

export default {
  FT_AUTOCOMPLETE,
  FT_SINGLE,
  FT_RANGE,
  FT_LIST,
  FT_SUGGESTION,
  FT_DATE,
  FT_CHECKBOX,
  FT_SWITCH,
  FT_MULTI_STRATEGY,
  FT_CONDITION_GROUP,
};
