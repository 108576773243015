import FilterItem from '../FilterItem';
import deprecatedFilterItems from '../deprecatedFilterItems';
import FilterListStorage from './FilterListStorage';
import { STRATEGY_NOT, STRATEGY_AND, STRATEGY_OR } from 'Components/filters/config';

class FilterList extends FilterItem {
  valuesStorage = null;
  constructor(conf, subcategory) {
    super(conf, subcategory);
    this.valuesStorage = new FilterListStorage();
  }

  init = async () => {
    this.valuesList = await this.#getListItems();
    this.valuesStorage.setValuesList(this.valuesList);
    return this.initFilter();
  };

  // eslint-disable-next-line no-unused-vars
  async apply(values, valuesMap, rawValue) {
    if (Array.isArray(values[0])) {
      this.strategy = STRATEGY_OR;
    } else {
      this.strategy = STRATEGY_AND;
    }
    if (rawValue && rawValue.length) {
      const keys = Object.keys(rawValue[0]);
      if (rawValue[0].not === true && rawValue[0][keys[0]] == null) {
        this.na = true;
        return;
      } else {
        this.na = false;
      }
    }
    let items = [];
    if (this.options.getListItems) {
      items = await this.options.getListItems();
    } else {
      items = this.options.valuesList;
    }
    this.valuesStorage.setValuesList(items);
    if (this.strategy === STRATEGY_AND) {
      this.value = values;
    } else {
      this.value = values[0];
    }
  }

  resetValue = () => {
    this.value = [];
  };

  collectValues() {
    const data = {};
    if (this.isFeature) {
      data.__feature = this.#collectFeatures();
      return data;
    }
    if (this.isNA) {
      return this.naFilter;
    }
    data[this.property] = this.value;
    return data;
  }

  #collectFeatures() {
    const { featureID, value, strategy } = this;
    if (this.isNA) {
      return { [featureID]: null, not: true };
    }
    if (Array.isArray(value) && strategy !== STRATEGY_OR) {
      const features = [];
      for (const opt of value) {
        const entry = { [featureID]: opt };
        if (strategy === STRATEGY_NOT) {
          entry.not = true;
        }
        features.push(entry);
      }
      return features;
    } else {
      return { [featureID]: value };
    }
  }

  getListValues(showDeprecated = false) {
    if (!this.useMoreButton) {
      return this.valuesList;
    }
    return this.#manageDeprecatedListItems(showDeprecated);
  }

  removeValueFromList(id) {
    this.value = this.value.filter((item) => item != id);
  }

  #manageDeprecatedListItems(showDeprecated) {
    const actualValues = this.valuesList.filter((value) => !value.deprecated);
    if (!showDeprecated) {
      return actualValues;
    }
    const deprecatedValues = this.valuesList.filter((value) => value.deprecated);
    return [...actualValues, ...deprecatedValues];
  }

  #getListItems = async (...args) => {
    const { getListItems, valuesList, filterByCat, useMoreButton } = this.options;
    let list;
    if (typeof getListItems === 'function') {
      list = await getListItems(...args);
    } else {
      list = valuesList || [];
    }
    if (filterByCat) {
      list = list.filter((el) => {
        if (!Array.isArray(el.categories)) {
          return true;
        }
        return el.categories.some((el) => {
          const id = typeof el === 'object' ? el.id : el;
          // noinspection EqualityComparisonWithCoercionJS
          return id == this.subCat;
        });
      });
    }
    if (useMoreButton) {
      const { deprecatedListPath } = this.options;
      const deprecatedList = deprecatedFilterItems[deprecatedListPath[0]][deprecatedListPath[1]];
      list = list.map((el) => ({
        ...el,
        deprecated: deprecatedList.items.includes(el[deprecatedList.value]),
      }));
      return list.sort((a, b) => a.name.localeCompare(b.name));
    }
    return list;
  };
}

export default FilterList;
