const { VUE_APP_API_URL, VUE_APP_RECAPTCHA_KEY, VUE_APP_BUILD_MODE, NODE_ENV, VUE_APP_ARCHIVE_URL } = process.env;

module.exports = {
  mode: VUE_APP_BUILD_MODE,
  env: NODE_ENV,
  recaptcha: {
    siteKey: VUE_APP_RECAPTCHA_KEY,
  },
  api: {
    host: VUE_APP_API_URL,
    url: VUE_APP_API_URL,
    suffix: '.jsonld',
    auth: '/login_check',
    refresh: '/api/token/refresh',
  },
  links: {
    archive: VUE_APP_ARCHIVE_URL,
  },
};
