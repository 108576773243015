import BaseModel from 'Models/BaseModel';
import { formatDate, saveBinaryData } from 'App/utils/Helpers';
import { MODULE_FILES } from '@/lib/enums/ApiModules';
import { DataTableHeader, TableHeaderActions, TableHeaderDate, TableHeaderName } from '@/lib/TypeDefs';
import Dataman from 'App/Dataman';
import { ROLE_STAFF } from 'App/constants/Roles';
import store from 'App/store/vuex';
import { CAT_COMPANIES, CAT_COMPONENTS, CAT_PRODUCTS } from 'App/utils/Collections';
import { mdiDelete, mdiFileDownloadOutline, mdiSquareEditOutline } from '@mdi/js';

export default class File extends BaseModel {
  static MODULE = MODULE_FILES;
  static category = MODULE_FILES;
  static getDefaults = () => ({
    category: File.category,
    products: [],
    companies: [],
    components: [],
  });

  static getHeaders = (context) => {
    return [
      new DataTableHeader({
        text: BaseModel.tr('ID'),
        value: 'id',
        width: '1%',
        sortable: true,
      }),
      new TableHeaderName({
        text: BaseModel.tr('Name'),
        prop: 'name',
        sortable: true,
      }),
      new TableHeaderName({
        text: BaseModel.tr('File Group'),
        prop: 'group',
        sortable: true,
      }),
      new TableHeaderDate({
        text: 'Upload Date',
        prop: 'createdAt',
      }),
      new TableHeaderActions({
        text: '',
        actions: ['quickEdit', 'download', 'remove'],
        width: '1%',
        cellClass: 'no-wrap',
        actionsData: {
          quickEdit: {
            icon: mdiSquareEditOutline,
            color: 'info',
            hint: BaseModel.tr('Quick edit'),
            visible: context && [CAT_COMPANIES, CAT_PRODUCTS, CAT_COMPONENTS].includes(context?.cat),
            callback: (item) => {
              return store.dispatch('sendAction', {
                action: 'quick-edit',
                data: { category: File.MODULE, item },
              });
            },
          },
          download: {
            icon: mdiFileDownloadOutline,
            color: 'info',
            hint: BaseModel.tr('Download file'),
            callback: (item) => {
              const { href, name } = item;
              Dataman.DB.files.download(href).then((data) => {
                saveBinaryData(data, name);
              });
            },
          },
          remove: {
            icon: mdiDelete,
            color: 'red',
            hint: BaseModel.tr('Delete'),
            restrictions: [ROLE_STAFF],
            prompt: true,
            callback: (item) => {
              return Dataman.DB.files.remove(item.id);
            },
            emit: 'reload',
            messages: {
              promptTitle: BaseModel.tr('Are you sure you want to delete this item?'),
              success: BaseModel.tr('The item has been deleted'),
            },
          },
        },
      }),
    ];
  };

  static CAT_URL = 'product-files';

  get href() {
    return this.props.downloadUrl;
  }

  get title() {
    return this.props.originalName;
  }

  get name() {
    return this.props.originalName;
  }

  get group() {
    return this.props.fileGroup?.name || '(none)';
  }

  get profileURL() {
    return `/api/files/${this.props.id}`;
  }

  get createdAtFormatted() {
    return formatDate(this.props.createdAt);
  }

  get createdAtShort() {
    return formatDate(this.props.createdAt, false);
  }

  get firstProduct() {
    return this._proxy.products[0];
  }

  get firstCompany() {
    return this._proxy.companies[0];
  }

  get firstComponent() {
    return this._proxy.components[0];
  }
}
