import { apiGet, apiPost, apiPatch } from '../../Dataman';
import { DEALS_PATHS } from './deals_paths';
import { createCustomerBodyAdapter } from './adapters';
import Backend from 'App/Dataman-Backend';

const { apiUrlSuffix } = Backend.config;

export const customersApiAdapter = {
  customers: {
    getList: (params) => apiGet(DEALS_PATHS.customers + apiUrlSuffix, { params }),
    getItem: (id, params) => apiGet(`${DEALS_PATHS.customers}/${id}`, { params }),
    update: (id, body) => apiPatch(`${DEALS_PATHS.customers}/${id}`, { data: createCustomerBodyAdapter(body) }),
    create: (data) => apiPost(DEALS_PATHS.customers, { data }),
  },
};
