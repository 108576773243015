import { apiGet, apiPatch, apiDelete, apiPost } from '../../Dataman';
import { DEALS_PATHS } from './deals_paths';

export const trackingParcelsApiAdapter = {
  getTrackingInformations: (params) => apiGet(DEALS_PATHS.trackingParcels, { params }),
  getTrackingInformation: (id) => apiGet(DEALS_PATHS.trackingParcels, { params: { deal: `${id}` } }),
  updateTrackingInformation: (id, data) => apiPatch(`${DEALS_PATHS.trackingParcels}/${id}`, { data }),
  deleteTrackingInformation: (id) => apiDelete(`${DEALS_PATHS.trackingParcels}/${id}`),
  createTrackingInformation: (data) => {
    return apiPost(DEALS_PATHS.trackingParcels, { data: createTrackingParcelAdapter(data) });
  },
};

function createTrackingParcelAdapter(requestBody) {
  return {
    trackingNumber: requestBody.trackingNumber,
    courierDeliveryService: requestBody.courierDeliveryService.toString(),
    url: requestBody.url,
    note: requestBody.note,
    deal: requestBody.productOrder.toString(),
    countryOfDeparture: requestBody.countryOfDeparture.toString(),
    countryOfDestination: requestBody.countryOfDestination.toString(),
    visibleToCustomer: requestBody.visibleToCustomer,
    delivered: requestBody.delivered,
  };
}
