import ItemMaster from 'App/ItemMaster';

export async function loadModules() {
  return ItemMaster.Dataman.DB.getModules().then((res) => {
    return res.data;
  });
}

export default {
  loadModules,
};
