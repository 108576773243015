import { apiGet, apiPost, apiPatch } from '../../Dataman';
import { DEALS_PATHS } from './deals_paths';
import Backend from 'App/Dataman-Backend';

const { apiUrlSuffix } = Backend.config;

export const deliverySerivicesApiAdapter = {
  getCourierDeliveryServises: (params) => {
    return apiGet(DEALS_PATHS.deliveryServices + apiUrlSuffix, { params, is_protected: true });
  },
  getCourierDeliveryServiseItem: (id, params) => apiGet(`${DEALS_PATHS.deliveryServices}/${id}`, { params }),
  updateCourierDeliveryServise: (id, data) => apiPatch(`${DEALS_PATHS.deliveryServices}/${id}`, { data }),
  createCourierDeliveryServise: (data) => apiPost(DEALS_PATHS.deliveryServices, { data }),
};
