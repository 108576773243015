import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from 'App/lang/en.json';
import { DEFAULT_LANGUAGE, FALLBACK_LANGUAGE } from 'App/constants/trans';

import { createI18n } from 'vue-i18n-bridge';

Vue.use(VueI18n, { bridge: true });

export const i18n = createI18n(
  {
    locale: DEFAULT_LANGUAGE,
    fallbackLocale: FALLBACK_LANGUAGE,
    messages: { en },
    silentTranslationWarn: true,
    legacy: false,
  },
  VueI18n
);

Vue.use(i18n);
