export default {
  computed: {
    isAdmin() {
      return this.$store.state.flags.isAdmin || false;
    },
  },
  methods: {
    hasRole(role) {
      return this.$store.state.user.hasAccess(role);
    },
    /**
     * @param {Array<string>} roles
     * @returns {boolean}
     */
    hasSomeRole(roles) {
      return roles?.some(this.hasRole);
    },
    /**
     * @param {Array<string>} roles
     * @returns {boolean}
     */
    hasRoles(roles) {
      for (const role of roles) {
        if (!this.hasRole(role)) {
          return false;
        }
      }
      return true;
    },
    hasAccess(category) {
      return this.isAdmin || this.$store.state.user.hasAccess(category);
    },
  },
};
