import axios from 'axios';
import { load as Recaptcha } from 'recaptcha-v3';
import { recaptcha as reConf, api as apiConf } from '../../project.config';
import {
  CAT_BRANDS,
  CAT_COMPANIES,
  CAT_COMPONENTS,
  CAT_CONTACTS,
  CAT_PATENTS,
  CAT_PRODUCTS,
  CAT_EVENTS,
} from 'App/utils/Collections';
import { isAuthTokenValid } from '@/lib/Utils';

const apiHost = apiConf.url;

const apiUrlActivitySpheres = `${apiHost}/api/activity_spheres`;
const apiUrlComponents = `${apiHost}/api/components`;
const apiUrlAdditionalFieldNames = `${apiHost}/api/additional_field_names`;
const apiUrlCategories = `${apiHost}/api/categories`;
const apiUrlCompanies = `${apiHost}/api/companies`;
const apiUrlCountries = `${apiHost}/api/countries`;
const apiUrlCustomerAddresses = `${apiHost}/api/customer_addresses`;
const apiUrlProductOrderAgentFiles = `${apiHost}/api/product_order_agent_files`;
const apiUrlProductOrderAgent = `${apiHost}/api/product_order_agent_invoices`;
const apiUrlProductMarketCountries = `${apiHost}/api/product_market_countries`;
const apiUrlCurrencies = `${apiHost}/api/currencies`;
const apiUrlCountryRegions = `${apiHost}/api/country_regions`;
const apiUrlContacts = `${apiHost}/api/contacts`;
const apiUrlProducts = `${apiHost}/api/products`;
const apiUrlProofRequests = `${apiHost}/api/proof_requests`;
const apiUrlPatents = `${apiHost}/api/patents`;
const apiUrlSearch = `${apiHost}/api/search`;
const apiUrlRecommendedProducts = `${apiHost}/api/recommended_products`;
const apiUrlNews = `${apiHost}/api/news`;
const apiUrlTrademarkHolders = `${apiHost}/api/trademark_holder_names`;
const apiUrlProjects = `${apiHost}/api/projects`;
const apiUrlAgents = `${apiHost}/api/agents`;
const apiUrlNewsImages = `${apiHost}/api/news_images`;
const apiUrlBrandImages = `${apiHost}/api/brand_images`;
const apiUrlUserGroups = `${apiHost}/api/usergroups`;
const apiUrlProductOrders = `${apiHost}/api/product_orders`;
const apiUrlMediaResourceTypes = `${apiHost}/api/media_resource_types`;
const apiUrlStandingReports = `${apiHost}/api/reports`;
const totalItemsPath = '/total_items';
const statistics = '/count_graph';
const apiUrlPaymentTypes = `${apiHost}/api/payment_types`;
const apiUrlModules = `${apiHost}/api/modules`;
const apiProductConsumableVariations = `${apiHost}/api/product_consumable_variations`;
const apiSampleConditions = `${apiHost}/api/sample_conditions`;
const apiSamples = `${apiHost}/api/samples`;
const apiProductOrderStatuses = `${apiHost}/api/order_statuses`;
const resetPasswordRequest = `${apiHost}/reset-password/request`;
const resetPasswordReset = `${apiHost}/reset-password/reset`;
const apiNiceClasses = `${apiHost}/api/nice_classes`;
const apiProductFiles = `${apiHost}/files/products/file`;
const apiUrlTrademarkItems = `${apiHost}/api/trademark_items`;
const apiUrlTrackingInformations = `${apiHost}/api/tracking_informations`;
const apiUrlEvents = `${apiHost}/api/events`;
const apiUrlOrderComments = `${apiHost}/api/order_comments`;

class BackendModel {
  config = {};
  constructor(config) {
    this._source = axios.CancelToken.source();
    this.config = {
      apiHost,
      resetPasswordRequest,
      resetPasswordReset,
      apiUrlActivitySpheres,
      apiUrlProducts,
      apiUrlComponents,
      apiUrlCompanies,
      apiUrlCountries,
      apiUrlProductMarketCountries,
      apiUrlCountryRegions,
      apiUrlContacts,
      apiUrlProofRequests,
      apiUrlPatents,
      apiUrlSearch,
      apiUrlNews,
      apiUrlProjects,
      apiUrlTrademarkHolders,
      apiUrlNewsImages,
      apiUrlBrandImages,
      apiUrlMediaResourceTypes,
      apiUrlStandingReports,
      apiProductConsumableVariations,
      apiUrlModules,
      apiUrlUserGroups,
      apiUrlProductOrderAgentFiles,
      apiUrlProductOrderAgent,
      apiSamples,
      apiUrlCustomerAddresses,
      apiUrlAgents,
      apiSampleConditions,
      apiUrlCategories,
      apiUrlAdditionalFieldNames,
      apiNiceClasses,
      apiUrlRecommendedProducts,
      apiProductFiles,
      apiProductOrderStatuses,
      apiUrlEvents,
      apiUrlCurrencies,
      apiUrlPaymentTypes,
      apiUrlOrderComments,
      apiUrlTrackingInformations,
      apiUrlUsers: `${apiHost}/api/users`,
      apiUrlResearchesAuthors: `${apiHost}/api/research_authors`,
      apiUrlResearchesDomains: `${apiHost}/api/research_domains`,
      apiUrlResearchesCategories: `${apiHost}/api/research_categories`,
      apiUrlResearches: `${apiHost}/api/researches`,
      apiUrlRoles: `${apiHost}/api/roles`,
      apiCourierDeliveryServises: `${apiHost}/api/courier_delivery_services`,
      apiUrlCustomers: `${apiHost}/api/customers`,
      apiUrlUsersStaffStatistics: `${apiHost}/api/users/staff_statistics`,
      apiComponentPackages: `${apiHost}/api/component_packages`,
      apiComponentCategories: `${apiHost}/api/component_categories`,
      apiUrlPatentsExplore: `${apiUrlPatents}/explore`,
      apiUrlLogin: `${apiHost}/login_check`,
      apiUrlRefresh: `${apiHost}/api/token/refresh`,
      apiUrlLeads: `${apiHost}/api/leads`,
      apiUrlUser: `${apiHost}/api/users/profile`,
      apiUrlProductsTotal: `${apiUrlProducts}${totalItemsPath}`,
      apiUrlProductsStats: `${apiUrlProducts}/chart`,
      apiUrlComponentsTotal: `${apiUrlComponents}${totalItemsPath}`,
      apiUrlComponentsStats: `${apiUrlComponents}${statistics}`,
      apiUrlCompaniesTotal: `${apiUrlCompanies}${totalItemsPath}`,
      apiUrlCompaniesStats: `${apiUrlCompanies}${statistics}`,
      apiUrlCountriesTotal: `${apiUrlCountries}${totalItemsPath}`,
      apiUrlContactsTotal: `${apiUrlContacts}${totalItemsPath}`,
      apiUrlTrademarksTotal: `${apiUrlTrademarkItems}${totalItemsPath}`,
      apiUrlPatentsTotal: `${apiUrlPatents}${totalItemsPath}`,
      apiUrlContactsStats: `${apiUrlContacts}${statistics}`,
      apiUrlTrademarks: `${apiHost}/api/trademark_items`,
      apiUrlReports: `${apiHost}/api/products/report`, // deprecated
      apiUrlReportGenerator: `${apiHost}/api/report_generator`,
      apiUrlProductStatuses: `${apiHost}/api/production_statuses`,
      apiUrlProductBrands: `${apiHost}/api/brands`,
      apiUrlProductFeatures: `${apiHost}/api/features`,
      apiUrlProductFeatureOptions: `${apiHost}/api/feature_options`,
      apiUrlCompanyCategories: `${apiHost}/api/company_categories`,
      apiUrlComments: `${apiHost}/api/comments`,
      apiUrlTypes: `${apiHost}/api/types`,
      apiAnalysisTypes: `${apiHost}/api/analysis_types`,
      apiProductAnalysisOrders: `${apiHost}/api/product_analysis_orders`,
      apiUrlSuffix: '.jsonld',
      apiUrlProductOrders,
      apiUrlProductImages: `${apiHost}/api/product_images`,
      apiUrlCompanyImages: `${apiHost}/api/company_images`,
      apiUrlPatentContents: `${apiHost}/api/patent_contents`,
      apiUrlPatentDescriptions: `${apiHost}/api/patent_descriptions`,
      apiUrlPatentClaims: `${apiHost}/api/patent_claims`,
      apiUrlPatentCpcClassification: `${apiHost}/api/patents/classification/cpc`,
      apiUrlPatentIpcClassification: `${apiHost}/api/patents/classification/ipc`,
      apiUrlUserBookmarks: `${apiHost}/api/user_bookmarks`,
      apiUrlUserFilters: `${apiHost}/api/user_filters`,
      apiUrlFilesGroups: `${apiHost}/api/file_groups`,
      apiFiles: `${apiHost}/api/files`,
      apiOrderStatuses: `${apiHost}/api/order_statuses`,
      apiCpcClassifications: `${apiHost}/api/patents/classification/cpc`,
      apiIpcClassifications: `${apiHost}/api/patents/classification/ipc`,
      apiPatents: `${apiHost}/api/patents`,
      apiPatentResearch: `${apiHost}/api/patent_researches`,
      apiPatentResearchImages: `${apiHost}/api/patent_research_images`,
      apiPatentResearchDocument: `${apiHost}/api/patent_research_documents`,
      apiPatentResearchDocumentComment: `${apiHost}/api/patent_research_document_comments`,
      apiPatentCitations: `${apiHost}/api/patent_citations`,
      apiUrlTrademarkStatuses: `${apiHost}/api/trademark_statuses`,
      apiUrlsTrademarkOffices: `${apiHost}/api/trademark_offices`,
      apiPatentInternalNotes: `${apiHost}/api/patent_internal_notes`,
      apiUrlTrackingInformationContents: `${apiHost}/api/tracking_information_contents`,
      apiUrlTrackingInformation: `${apiHost}/api/tracking_informations`,
      apiUrlTrademarksSuggestions: `${apiHost}/api/trademark_items/suggestion`,
      apiUrlCountriesProductMarkets: `${apiHost}/api/countries/product_markets`,
      apiUrlProductVariations: `${apiHost}/api/product_variations`,
      apiUrlEventTypes: `${apiHost}/api/event_types`,
      apiUrlEventThemes: `${apiHost}/api/event_themes`,
      apiUrlEventAccesses: `${apiHost}/api/event_accesses`,
      apiUrlEventStatus: `${apiHost}/api/event_statuses`,
      apiUrlEventAtendingRequest: `${apiHost}/api/event_attending_requests`,
      apiUrlEventImages: `${apiHost}/api/event_images`,
      apiUrlAttendingRequests: `${apiHost}/api/event_attending_requests`,
      apiUrlMarketCountriesFiles: `${apiHost}/files/product_market_countries`,
      apiUrlChangePassword: `${apiHost}/change-password`,
      apiUrlOrderCommentsFiles: `${apiHost}/api/order_comment_files`,
      staffStats: {
        [CAT_BRANDS]: `${apiHost}/api/brand_staff_statistics`,
        [CAT_COMPANIES]: `${apiHost}/api/company_staff_statistics`,
        [CAT_COMPONENTS]: `${apiHost}/api/component_staff_statistics`,
        [CAT_CONTACTS]: `${apiHost}/api/contact_staff_statistics`,
        [CAT_PATENTS]: `${apiHost}/api/patent_staff_statistics`,
        [CAT_PRODUCTS]: `${apiHost}/api/product_staff_statistics`,
        [CAT_EVENTS]: `${apiHost}/api/event_staff_statistics`,
      },
      staffStatsItem: {
        [CAT_BRANDS]: `${apiHost}/api/brand_staff_item_statistics`,
        [CAT_COMPANIES]: `${apiHost}/api/company_staff_item_statistics`,
        [CAT_COMPONENTS]: `${apiHost}/api/component_staff_item_statistics`,
        [CAT_CONTACTS]: `${apiHost}/api/contact_staff_item_statistics`,
        [CAT_PATENTS]: `${apiHost}/api/patent_staff_item_statistics`,
        [CAT_PRODUCTS]: `${apiHost}/api/product_staff_item_statistics`,
        [CAT_EVENTS]: `${apiHost}/api/event_staff_statistics`,
      },
      ...(config || {}),
    };
    this.skipErrorChecking = [this.config.apiUrlChangePassword];
    // this._loadRecaptcha(reConf.siteKey).then();
  }
  async _loadRecaptcha(key) {
    return Recaptcha(key, { autoHideBadge: true }).then((recaptcha) => {
      this._recaptcha = recaptcha;
      return recaptcha;
    });
  }
  _token = '';
  _refresh_token = '';
  _onError;
  _onRefreshToken;
  _showDrafts = false;
  refreshToken = async (refresh_token) => {
    return this.post({
      url: this.config.apiUrlRefresh,
      data: { refresh_token },
      is_protected: true,
      auth: false,
    })
      .then((res) => res.data)
      .catch();
  };
  async validateToken() {
    if (!isAuthTokenValid(this._token)) {
      if (this._refresh_token) {
        const tokens = await this.refreshToken(this._refresh_token).catch((err) => {
          console.log('Auth: can not refresh token');
          console.error(err);
          return false;
        });
        if (typeof this._onRefreshToken === 'function') {
          this._onRefreshToken(tokens);
        }
        this.setToken(tokens);
      } else {
        return false;
      }
    }
    return this._token;
  }
  async getAuthHeaders(restHeaders = {}) {
    const headers = { ...(restHeaders || {}) };
    if (this._token) {
      const token = await this.validateToken();
      if (token === false) {
        return false;
      }
      headers['Authorization'] = `Bearer ${token}`;
    }
    return headers;
  }
  getRecaptchaToken = async (action) => {
    try {
      if (!this._recaptcha) {
        await this._loadRecaptcha(reConf.siteKey);
      }
      return await this._recaptcha.execute(action);
    } catch (e) {
      throw new Error('Can not initialize ReCaptcha');
    }
  };
  CatchError = (err) => {
    if (typeof this._onError === 'function') {
      this._onError(err);
    }
  };
  setToken = ({ token, refresh_token }) => {
    this._token = token;
    this._refresh_token = refresh_token;
  };
  get = async (options, clearParams = false) => {
    // eslint-disable-next-line no-unused-vars
    const { url, method, params, ...rest } = options;
    const _params = params || {};
    if (!clearParams) {
      if (!('draft' in _params)) {
        if (!this._showDrafts) {
          _params.draft = false;
        }
      }
    }
    return this.request(url, 'get', {
      ...rest,
      contentType: 'application/json; charset=utf-8',
      params: _params,
    });
  };
  post = async (options = {}) => {
    // eslint-disable-next-line no-unused-vars
    const { url, method, ...rest } = options;
    return this.request(url, 'post', rest);
  };
  patch = (options = {}) => {
    // eslint-disable-next-line no-unused-vars
    const { url, method, ...rest } = options;
    return this.request(url, 'patch', rest);
  };
  put = (options) => {
    // eslint-disable-next-line no-unused-vars
    const { url, method, ...rest } = options || {};
    return this.request(url, 'put', rest);
  };
  delete = (options) => {
    // eslint-disable-next-line no-unused-vars
    const { url, method, params, ...rest } = options || {};
    return this.request(url, 'delete', { ...rest, data: params });
  };
  download = async (options) => {
    const { url, ...rest } = options;
    const config = {
      ...rest,
      contentType: 'application/json; charset=utf-8',
      responseType: 'arraybuffer',
      headers: await this.getAuthHeaders(),
    };

    return this.request(url, 'post', config);
  };
  loadData = (options) => {
    return this.get({ ...options, responseType: 'arraybuffer' });
  };
  loadBlobData = (options) => {
    return this.get({ ...options, responseType: 'blob' });
  };
  postJSON = (data, options = {}) => {
    return this.post({
      ...options,
      headers: {
        ...(options?.headers || {}),
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(data),
    });
  };
  async request(url, method = 'get', options = {}) {
    let { is_protected, auth, headers, ld, ...rest } = options || {};
    if (auth == undefined) {
      auth = true;
    }
    rest.headers = auth === false ? headers || {} : await this.getAuthHeaders(headers);
    if (rest.headers === false) {
      return;
    }
    if (is_protected) {
      const [path] = new URL(url).pathname
        .replace('/api', '')
        .replace(/[^A-Za-z/_]/g, '_')
        .substr(1)
        .split('.');
      rest.headers['recaptcha'] = await this.getRecaptchaToken(`${method}_${path}`);
    }
    if (ld) {
      rest.headers['Accept'] = 'application/ld+json';
    }
    return axios({
      url,
      method,
      ...rest,
      cancelToken: this._source.token,
    }).catch((err) => {
      if (!this.skipErrorChecking.includes(url)) {
        this.CatchError(err);
      }
      throw err;
    });
  }
  get token() {
    return this._token;
  }
  set onError(callback) {
    if (typeof callback !== 'function') {
      throw new Error('Callback must be a function');
    }
    this._onError = callback;
  }
  set onRefreshToken(callback) {
    if (typeof callback !== 'function') {
      throw new Error('Callback must be a function');
    }
    this._onRefreshToken = callback;
  }
  set showDrafts(state) {
    this._showDrafts = state || false;
  }

  cancel = () => {
    if (this._source) {
      this._source.cancel();
      this._source = axios.CancelToken.source();
    }
  };
  testFail = () => {
    const err = { code: 401, message: 'Expired JWT Token' };
    this.CatchError(err);
  };
}

const Backend = new BackendModel();

export default Backend;
