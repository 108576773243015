export const ROLE_STAFF = 'role_staff';
export const ROLE_CUSTOMER = 'role_customer';
export const ROLE_ADMIN = 'role_admin';
export const ROLE_VAPING = 'role_vaping';
export const ROLE_PATENT_SPECIALIST = 'role_patent_specialist';
export const ROLE_DIGITAL_HEALTHCARE = 'role_digital_healthcare';
export const ROLE_WELLNESS_STIMULATION = 'role_wellness_stimulation';
export const ROLE_PRODUCTS = 'role_products';
export const ROLE_RESEARCH = 'role_research';
export const ROLE_COMPANIES = 'role_companies';
export const ROLE_CONTACTS = 'role_contacts';
export const ROLE_LOGISTIC_MANGER = 'role_logistic_manager';
export const ROLE_FILTER_MANAGER = 'role_filter_manager';
export const ROLE_DEMO_CUSTOMER = 'role_demo_customer';
export const ROLE_RECOMMENDED_PRODUCTS = 'role_recommended_products';
export const ROLE_ACCOUNTANT = 'role_accountant';
export const ROLE_PRODUCTS_EVIDENCE = 'role_products_evidence';
