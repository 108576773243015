/**
 * Checks if history is actual and adds event listener to save last visit date
 * @throws {Error}
 */
export function enableHistory() {
  const historySavingTime = 1000 * 60 * 10;
  const lastLeft = new Date(localStorage.getItem('lastLeft')).getTime();
  const isHistoryActual = Date.now() - lastLeft < historySavingTime;
  if (!isHistoryActual) {
    localStorage.removeItem('history');
  }
  window.addEventListener('beforeunload', onUnload);
}
/**
 * Removes event listenter saves last visit date
 * @throws {Error}
 */
export function disableHistory() {
  window.removeEventListener('beforeunload', onUnload);
}

function onUnload() {
  const timestamp = new Date().toISOString();
  localStorage.setItem('lastLeft', timestamp);
}
