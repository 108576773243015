export default function parseRangeRawKey(key) {
  const prop = key.match(/\[.+]/i)[0]?.replace(/[[\]]/g, '');
  if (prop) {
    const fromKey = 'from';
    const toKey = 'to';
    const key = {
      after: fromKey,
      strictly_after: fromKey,
      gt: fromKey,
      gte: fromKey,
      before: toKey,
      strictly_before: toKey,
      lt: toKey,
      lte: toKey,
    }[prop];
    return key;
  }
}
