import { FT_SINGLE } from '../FilterTypes';
import { CAT_SAMPLE_CONDITIONS } from 'App/utils/Collections';

const groups = {
  general: {
    name: 'General',
    groupOrderIndex: 0,
  },
};

export default {
  category: CAT_SAMPLE_CONDITIONS,
  filters: [
    {
      id: 'id',
      label: 'ID Number',
      type: FT_SINGLE,
      group: groups.general,
      hint: '',
    },
  ],
};
