import FilterItem from '../FilterItem';

class FilterSingle extends FilterItem {
  constructor(conf, subcategory) {
    super(conf, subcategory);
  }

  collectValues() {
    if (this.isNA) {
      return this.naFilter;
    }
    return {
      [this.property]: this.parseQueryString(this.value),
    };
  }

  resetValue = () => {
    this.value = null;
  };

  parseQueryString(inputString) {
    if (this.splittable) {
      if (!/[,\s;]/.test(inputString)) {
        return inputString;
      }
      const resultArray = inputString.split(/[,\s;]/);
      const filteredArray = resultArray.filter((str) => str.trim() !== '');

      return filteredArray;
    } else {
      return inputString;
    }
  }
}

export default FilterSingle;
