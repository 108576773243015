import Vue from 'vue';
import Vuetify, { VIcon, VBtn, VCard } from 'vuetify/lib';
import zhHans from 'vuetify/lib/locale/zh-Hans';

Vue.use(Vuetify, { components: { VIcon, VBtn, VCard } });

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
  },
  lang: {
    locales: { zhHans },
    current: 'en',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#ee44aa',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    },
  },
});
