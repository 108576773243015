import Dataman from 'App/Dataman';
import { sortBy } from 'App/utils/Helpers';
import { USP_TYPE_ID } from '@/app/utils/Collections';

export async function countries() {
  return Dataman.Filter.getCountries({ params: { itemsPerPage: 300 } }).then((res) => {
    return res.data.sort(sortBy('name'));
  });
}

export async function regions() {
  return Dataman.Filter.getCountryRegions({
    params: { itemsPerPage: 300 },
  }).then((res) => {
    return res.data.sort(sortBy('name'));
  });
}

export async function currencies() {
  return await Dataman.DB.getCurrencies({ params: { 'order[alphabeticCode]': 'asc', itemsPerPage: 300 } }).then(
    (res) => {
      return res.data;
    }
  );
}

export async function agents() {
  return Dataman.DB.agents.getList().then((res) => {
    return res.data['hydra:member'].sort(sortBy('name'));
  });
}

export async function productionStatuses() {
  return Dataman.Filter.getProductStatuses({
    params: { itemsPerPage: 300 },
  }).then((res) => {
    return res.data.sort(sortBy('ordering'));
  });
}

export async function userRoles(params = {}) {
  return Dataman.DB.users
    .getRoles({
      itemsPerPage: 300,
      ...(params || {}),
    })
    .then((res) => {
      return res.data.sort(sortBy('title'));
    });
}

export async function productTypes(params = {}) {
  return Dataman.Filter.getProductTypes({
    itemsPerPage: 300,
    ...(params || {}),
  }).then((res) => {
    return res.data.sort(sortBy('name'));
  });
}

export async function uspCategories() {
  return Dataman.Filter.getProductTypes({
    itemsPerPage: 300,
    'parent.id': USP_TYPE_ID,
  }).then((res) => {
    return res.data.sort(sortBy('name'));
  });
}

export async function companyTypes() {
  return Dataman.Filter.getCompanyTypes({ itemsPerPage: 300 }).then((res) => {
    return res.data.sort(sortBy('name'));
  });
}

export async function contactTypes() {
  return Dataman.Filter.getContactTypes({ itemsPerPage: 300 }).then((res) => {
    return res.data.sort(sortBy('name'));
  });
}

export async function productFeatures(params = {}) {
  return Dataman.Filter.getProductFeatures({
    params: { itemsPerPage: 300, ...params },
    headers: { 'cache-control': 'no-cache' },
  }).then((res) => {
    return res.data.sort(sortBy('name'));
  });
}

export async function companyCategories() {
  return Dataman.Filter.getCompanyCategories({
    params: { itemsPerPage: 300 },
  }).then((res) => {
    return res.data.sort(sortBy('name'));
  });
}

export async function activitySpheres() {
  return Dataman.DB.activitySpheres.getList({ params: { itemsPerPage: 300 } }).then((res) => {
    return res.data.sort(sortBy('name'));
  });
}

export async function mediaResourceTypes() {
  return Dataman.Filter.getMediaResourceTypes({
    params: { itemsPerPage: 300 },
  }).then((res) => {
    return res.data.sort(sortBy('name'));
  });
}

export async function paymentTypes() {
  return Dataman.DB.paymentTypes.getList().then((res) => {
    return res.data['hydra:member'];
  });
}

export async function productImages(id) {
  return Dataman.DB.getProductImages(id);
}

export async function trademarks() {
  return Dataman.DB.getTrademarks().then((res) => {
    return res.data['hydra:member'];
  });
}

export async function customers() {
  return Dataman.DB.customers.getList().then((res) => {
    return res.data['hydra:member'].sort(sortBy('name')).map((el) => ({ name: el.name, id: el.id }));
  });
}

function getCategoryName(name) {
  let nameParts = name.split('/');
  return nameParts[nameParts.length - 1];
}

export async function SPCategories() {
  return Dataman.DB.researchesCategories.getList().then((res) => {
    return res.data['hydra:member'].sort(sortBy('name')).map((el) => ({ _text: getCategoryName(el.name), id: el.id }));
  });
}

export async function courierDeliveryServises() {
  return Dataman.DB.getCourierDeliveryServises().then((res) => {
    return res.data['hydra:member'];
  });
}

export async function projects() {
  return Dataman.DB.projects.getList().then((res) => {
    return res.data['hydra:member'];
  });
}
