import FilterItem from '../FilterItem';
import FilterListStorage from './FilterListStorage';

class FilterAutocomplete extends FilterItem {
  valuesStorage = null;

  constructor(conf, subcategory) {
    super(conf, subcategory);
    this.valuesStorage = new FilterListStorage();
  }

  setValuesList(valuesList) {
    this.valuesStorage.setValuesList(valuesList);
  }

  async apply(values) {
    if (values.length == 1 && values[0] === false) {
      this.na = true;
      return;
    }
    this.loadersContext = {
      ...this.loadersContext,
      itemsKey: 'id',
      itemsToLoad: values[0],
    };

    const items = await this.fieldConf.items();
    this.valuesStorage.setValuesList(items);
    this.value = values[0];
    this.resetLoadersContext();
  }

  resetValue = () => {
    this.value = [];
  };

  get valuesList() {
    return this.valuesStorage.valuesList;
  }

  getValueLabel = (id) => {
    return this.valuesStorage.getValueLabel(id);
  };

  removeValueFromList(id) {
    this.value = this.value.filter((item) => item != id);
  }
}

export default FilterAutocomplete;
