import { CAT_COMPANY_NEWS } from 'App/utils/Collections';
import { FT_DATE, FT_AUTOCOMPLETE } from '../FilterTypes';
import { searchNewsDomains, NewsDomainsLoader, tagsLoader, searchNewsTags } from './loaders';
import { ROLE_STAFF } from '@/app/constants/Roles';

const groups = {
  general: {
    name: 'General',
    groupOrderIndex: 0,
  },
  date: {
    name: 'Date',
    groupOrderIndex: 1,
  },
};

export default {
  category: CAT_COMPANY_NEWS,
  filters: [
    {
      id: 'snippetDate',
      type: FT_DATE,
      label: 'Published on',
      group: groups.date,
    },
    {
      id: 'domain.id',
      label: 'Domain',
      group: groups.general,
      type: FT_AUTOCOMPLETE,
      placeholder: 'Choose domain',
      fieldConf: {
        multiple: false,
        deletebleChips: true,
        chips: true,
        searcher: searchNewsDomains,
        items: NewsDomainsLoader,
      },
    },
    {
      id: 'tags.id',
      label: 'Tags',
      group: groups.general,
      type: FT_AUTOCOMPLETE,
      placeholder: 'Choose tags',
      role: [ROLE_STAFF],
      fieldConf: {
        multiple: false,
        deletebleChips: true,
        chips: true,
        searcher: searchNewsTags,
        items: tagsLoader,
      },
    },
  ],
};
