export default {
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    xs() {
      return this.windowWidth < 300;
    },
    sm() {
      return this.windowWidth >= 300 && this.windowWidth < 640;
    },
    smDown() {
      return this.windowWidth < 640;
    },
    md() {
      return this.windowWidth >= 640 && this.windowWidth < 1024;
    },
    mdDown() {
      return this.windowWidth < 1024;
    },
    lg() {
      return this.windowWidth >= 1024 && this.windowWidth < 1300;
    },
    lgDown() {
      return this.windowWidth < 1300;
    },
    xl() {
      return this.windowWidth >= 1300;
    },
  },
  created() {
    window.addEventListener('resize', this.onWindowResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize);
  },
  methods: {
    onWindowResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
